export default {
  translation: {
    albanian: "Shqip",
    english: "Anglisht",
    "select language": "Zgjedh gjuhën",
    dashboard: "Ballina",
    "find it": "Qysh e lyp, e gjen!",
    Prona: "Prona",
    "all types":
      "Të gjitha llojet e PATUNDSHMËRIS me qera apo shitje, RESTORANTET më të mirat si dhe MAKINAT me qera dhe shitje në kulmi-ks.",
    "about us": "Rreth nesh",
    "real estate": "Patundshmëri",
    gastronomi: "Restorantet",
    cars: "Makina",
    rent: "Qera",
    buy: "Blej",
    contactus: "Na kontakto",
    follow_us: "Na ndiq",
    email_not_saved_anywhere:
      "Emaili juaj nuk do të publikohet. Ju lutem mbushni fushat më poshtë.",
    "room mate": "Cimer/e",
    "find city": "Kerko qytetin/lagjen",
    "post type": "Lloji postimit",
    price: "Çmimi",
    min: "min",
    max: "max",
    search: "Kërko",
    "quick solutions": "Ofrojmë zgjedhje të shpejtë për ju!",
    "real estate as you like": "Shtëpi apo banesë ashtu siç e kërkoni",
    "your preferences": "Preferencat tua i gjeni tek ne",
    "best gastronomy": "Restorantet më të mirat në Kosovë",
    "gastronomy types": "Kategori të ndryshme të gastronomisë",
    "fast find": "Kërkim i shpejtë për vozitje të rehatshme",
    "rent buy cars": "Makina me qera apo shitje",
    "find quick": "E gjeni shumë shpejtë",
    "find within 5 minutes": "Brenda 5 minuta do e gjeni at që ju e kërkoni",
    "best apartments": "Apartamentet më të mirat",
    "best offers": "Ju sjellim ofertat më të mirat.",
    "visit all": "Vizito të gjitha.",
    "ad space": "Hapësirë për reklamim",
    "building space ad": "Kjo hapësirë është për reklamimin e ndërtesve.",
    "contact us at":
      "Na kontaktoni në numrin e telefonit (347) 628-4593 ose ne contact@ontrine.com",
    "guide map": "Harta e faqes",
    contact: "Kontakti",
    manage: "Manage",
    "my posts": "Postimet e mia",
    account: "Llogaria",
    logout: "Ç'kyçu",
    login: "Kyçu",
    "Numri i kateve te shtepise*": "Numri i kateve te shtepise*",
    email_placeholder: "Shkruani emailin këtu...",
    pass_placeholder: "Shkruani fjalëkalimin këtu...",
    "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*":
      "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*",
    "new user": "Jeni i ri në faqen KulmiKs",
    register: "Regjistrohuni",
    "write email here": "Shkruani emailin ketu...",
    "write pass here": "shkruani fjalekalimin ketu...",
    "reset pass": "Reset fjalekalimin",
    "create account": "Krijo llogari dhe bëhu pjesë e Kulmit",
    "you have account": "Keni një llogari?",
    "login here": "Kyquni ketu",

    people: "Personat",
    "car model": "Modeli vertures",
    "searching posts": "Postimet per kerkim",
    "Postimet per Kerkim": "Postimet per kerkim",
    "add post": "Shto postim",
    no_posts_found: "Nuk u gjenden postime për kërkimin tuaj",
    home: "Shtepi",
    apartment: "Banesë",
    land: "Tokë",
    office: "Zyrë",
    villa: "Villë",
    local: "Lokal",
    food: "Ushqim",
    coffee: "Kafe",
    weddings: "Dasma",
    Dasma: "Dasma",
    music: "Muzikë (Party)",
    "owning paper": "Fletë poseduese",
    "upload photo": "Ngarko fotografi",
    "select neighborhood":
      "Ju lutem të caktoni ne vijim lagjen e saktë në mënyrë që postimi juaj të gjendet më shpejtë. Më poshtë keni mundësinë e fshehjes së hartës nga postimi juaj.",
    "select location": "Cakto lokacionin në hartë:",
    "do not show map": "Mos shfaq harten ne postim",
    "add this post": "Shto postimin",
    preview: "Pamja paraprake",
    "chosen photo": "Ketu vendoset fotoja e perzgjedhur",
    "chosen title": "titulli vendoset ketu",
    "posted on": "Postuar më",
    month: "Muaj",
    day: "Ditë",

    "main photo": "Caktoni foton kryesore per postin tuaj*",

    details: "Detajet",

    general: "Te pergjithshme",
    description: "Pershkrimi",
    extra: "Shtesat",
    map: "Lokacioni",
    send: "Dergo",
    "contact owner": "Kontakto Pronarin",
    "Emri..": "Emri",
    "Mbiemri..": "Mbiemri",
    "Numri telefonit..": "Numri telefonit",
    "Mesazhi..": "Mesazhi",
    delete: "Fshij",
    back: "Kthehu",
    "click to zoom": "Kliko per te zmadhuar foton",
    update: "Perditeso",
    "make active": "Beje aktive",
    archive: "Arkivo postimin",
    "one room": "Garsoniere",
    seats: "Ulese",
    "manufactured at": "Prodhuar me",
    doors: "Dyer",
    transmission: "Transmisioni",
    petrol: "Karburanti",
    colors: "Ngjyrat",
    bathrooms: "Banjo",
    bathroom: "Banjo",
    "owning letter": "Leter poseduese",
    "fb contact": "Kontakto ne facebook",
    "in place deal": "Me marreveshje",
    agency: "Agjensioni",
    email: "Email",
    "fb link": "Linku ne facebook",
    "posted at": "Postuar me",
    "Upload photos": "Ngarko fotot",
    "chose up to 15": "Zgjedh deri ne 15 foto",
    "chosen photo": "Foto e perzgjedhur vendoset ketu",
    "chosen title": "Titulli vendoset ketu",

    fun: "Ahengje",

    //forms
    Qera: "Qera",
    Blej: "Blej",
    Shitje: "Shitje",
    "Cimer/e": "Cimer/e",
    "Lloji i postimit": "Lloji i postimit",
    Banesë: "Banesë",
    Shtëpi: "Shtëpi",
    Zyrë: "Zyrë",
    Tokë: "Tokë",

    Villë: "Villë",
    Banese: "Banesë",
    Shtepi: "Shtëpi",
    Zyre: "Zyrë",
    Toke: "Tokë",
    restaurantType: "Lloji i restorantit",
    Villë: "Villë",
    Lokal: "Lokal",
    Ahengje: "Ahengje",
    "Të gjitha": "Të gjitha",
    Çmimi: "Çmimi",
    Party: "Party",
    Ushqim: "Ushqim",
    Kafe: "Kafe",
    Personat: "Personat",
    "Modeli vetures": "Modeli vetures",
    "Muzikë (Party)": "Muzikë (Party)",
    "Titulli i postimit*": "Titulli i postimit*",
    Currency: "Valuta",
    "Currency*": "Valuta",
    "Qyteti/Rajoni*": "Qyteti/Rajoni*",
    "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)":
      "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)",
    "Marka*": "Marka*",
    "Hapësira (m2)*": "Hapësira (m2)*",
    "Hapësira m2 (vendosni vetem numrin)*":
      "Hapësira m2 (vendosni vetem numrin)*",
    "Modeli*": "Modeli*",
    "Modeli vetures*": "Modeli vetures*",
    "Transmisioni*": "Transmisioni*",
    "Ngjyra vetures*": "Ngjyra vetures*",
    "Ngjyra*": "Ngjyra*",
    "Karburanti*": "Karburanti*",
    "Lloji karburantit*": "Lloji karburantit*",
    "Viti prodhimit*": "Viti prodhimit*",
    "Kilometrazha*": "Kilometrazha*",
    "Numri dyerve*": "Numri dyerve*",
    "Numri uleseve*": "Numri uleseve*",
    "Vendi origjines*": "Vendi origjines*",
    "Banjo*": "Banjo*",
    "Numri kateve te shtepise*": "Numri kateve te shtepise*",
    'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*':
      'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*',
    "Dhoma*": "Dhoma*",
    "Linku i profilit ne facebook:(opsional)":
      "Linku i profilit ne facebook:(opsional)",

    "Unit*": "Njesia matese*",
    Unit: "Njesia matese",
    "Numri i katit (vendos 0 për përdhesë)*":
      "Numri i katit (vendos 0 për përdhesë)*",
    "Numri personave te lejuar*": "Numri personave te lejuar*",
    "Fletë poseduese*": "Fletë poseduese*",
    "I doganuar*": "I doganuar*",
    Përshkrimi: "Përshkrimi",
    Karakteristikat: "Karakteristikat",
    "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')":
      "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')",
    "Çmimi*": "Çmimi",
    "Çmimi ditor*": "Çmimi ditor",
    "Zgjedh deri ne 15 foto*": "Zgjedh deri ne 15 foto",
    "Posti u shtua me sukses!": "Posti u shtua me sukses!",
    "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.":
      "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.",
    "Ju lutem të caktoni titullin e postit.":
      "Ju lutem të caktoni titullin e postit.",
    "Ju lutem të caktoni llojin e postit":
      "Ju lutem të caktoni llojin e postit",
    "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar":
      "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar",
    "Ju lutem të caktoni hapësirën.": "Ju lutem të caktoni hapësirën.",
    "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.":
      "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.",
    "Ju lutem të caktoni numrin e banjove.":
      "Ju lutem të caktoni numrin e banjove.",
    "Ju lutem të caktoni numrin e dhomave.":
      "Ju lutem të caktoni numrin e dhomave.",
    "Ju lutem të caktoni çmimin.": "Ju lutem të caktoni çmimin.",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto",
    "Se shpejti!": "Se shpejti!",
    "Kërko lokacionin..": "Kërko lokacionin..",
    value: "value",
    "Kërko qytetin/lagjen": "Kërko qytetin/lagjen",
    "Te gjitha": "Te gjitha",
    Kërko: "Kërko",
    "Kërko veturën": "Kërko veturën",

    dhoma: "dhoma",
    dhomë: "dhomë",
    banjo: "banjo",
    m2: "m2",
    Viti: "Viti",
    Automatik: "Automatik",
    kate: "kate",

    Wifi: "Wifi",
    Parking: "Parking",
    "TV i mençur": "TV i mençur",
    Depo: "Depo",
    "Nxemje qendrore": "Nxemje qendrore",
    Klimë: "Klimë",

    //about us
    line1: "Kush jemi ne?",
    line2:
      "Mirë se vini në faqen tonë, ku ofrojmë një platformë të gjerë për reklamimin dhe zbulimin e një game të gjerë pronash, duke përfshirë automjete të disponueshme për qira ose shitje. Gjithashtu, lehtësojmë eksplorimin e përvojave gastronomike të ndryshme.\n" +
      "\n" +
      "Si ndërmjetës, lidhim shitësit me blerësit, pronarët me qirasësit, si dhe shitësit dhe pronarët me agjentët. Ju lutemi, vini re se ne nuk shesim ose qiramarrim apartamente/shtëpi drejtpërdrejt; në vend të kësaj, veprim si ndihmës në këto transaksione.\n" +
      "\n" +
      "Shërbimet tona përfshijnë publikimin e shitjes së pronave dhe qiramarrjes së makinave, duke lejuar transaksione efikase dhe të suksesshme. Përveç kësaj, ofrojmë një gamë të gjerë mundësish reklamimi për restorante dhe bare, duke ju ndihmuar të zbuloni shijet kulinarë të reja.\n" +
      "\n" +
      "Lirisht shfletoni faqen tonë dhe përfitoni nga mundësitë që ofrojmë në fushën e pronave, makinave dhe gastronomisë.",
    line3: "po ashtu për të gjetur të gjitha llojet e gastronomis!",
    line4:
      "Ne nuk jemi agjent dhe nuk shesim/lëshojmë me qera banesa/shtëpi, ne jemi ndërmjetësues.",
    line5:
      "Ndërmjetësues mes shitësit dhe blerësit, qiradhënësit dhe qiramarrësit, po ashtu dhe shitësit me agjent, qiradhënësit me agjent!",
    line6: "Ne ofrojmë publikimin e shitjeve dhe lëshimin me qera të veturave.",
    line7:
      "Po ashtu ofrojmë shpalljen/gjetjen e restoranteve dhe bareve të ndryshme.",
    line8: "Çka ofrojmë ne : ",
    line9:
      "Pronat me qira: Zbuloni një zgjedhje të ndryshme apartamentesh dhe shtëpish të disponueshme për qira, të përshtatura sipas preferencave dhe nevojave tuaja.",
    line10:
      "Pronat për Shitje: Zbuloni një gamë të gjerë apartamentesh dhe shtëpish për shitje, duke ofruar mundësi për blerjen e shtëpisë ose investime në pronësi të patundshme.",
    line11:
      "Qiramarrja e Makinave: Gjeni makinën perfekte për nevojat tuaja përmes shërbimit tonë të besueshëm të qiramarrjes së makinave, duke siguruar opsione të rehatshme transporti.",
    line12:
      "Makina për Shitje: Shfletoni inventarin tonë të makinave të disponueshme për blerje, që i përshtaten buxhetit dhe preferencave të ndryshme.",
    line13:
      "Gastronomia: Zhytuni në botën e gastronomisë me ofertat tona të gjerësisht të pasura.",
    line14:
      "Restorante dhe Bare: Zbuloni një gamë të ndryshme restorantesh dhe bare, nga kafene të ngrohta deri te ambiente për darka të sofistikuara, të gjitha në duart tuaja.",
    line15: "Të shpallura nga:",
    line16:
      "Pronarët e Pronave ose Agjentët e Bienesave të Patundshme: Në faqen tonë, listat e pronave janë të njoftuara nga pronarët e pronave që dëshirojnë të shesin ose qiramarrin pronat e tyre, si dhe nga agjentët e licencuar të bienave të patundshme që përfaqësojnë klientë në tregun e pasurive të patundshme.",
    line17:
      "Restorante: Zbuloni një gamë të gjerë të mundësive të ngrenies që janë të njoftuara drejtpërdrejt nga vetë restorantet, duke ju lejuar të eksploroni ofertat e tyre unike dhe ambientin e tyre.",
    line18:
      "Kompani Qiramarrje Makinash, Shitës Automjetesh ose Pronarë Individualë: Seksioni ynë i qiramarrjes së makinave paraqet njoftimet nga kompanitë e besueshme të qiramarrjes së makinave, shitësit e automjeteve, si dhe pronarët individualë, duke siguruar një përvojë të besueshme dhe pa vështirësi për qiramarrjen e makinave.",
    line19: "Qëllimi ynë:",
    line20:
      "Në faqen tonë, qëllimi ynë është të ju ofrojmë zgjidhje më të shpejta dhe të përshtatshme për blerjen dhe shitjen, të përshtatura sipas preferencave dhe mënyrës suaj të përshtatshme.",
    line22:
      "Ne synojmë të ju fuqizojmë që të merrni vendimet për blerje ose shitje në mënyrën tuaj, duke ju lejuar lirinë për të zgjedhur atë që dëshironi dhe ku e dëshironi. Platforma jonë është projektuar për të lehtësuar procesin, duke ju kursyer kohë dhe mund të panevojshëm, ndërsa siguron një përvojë të padukshme.",
    line21:
      "Faqja u dizajnua nga Adonis Rexha, Logo nga Fatos Rexhepi dhe u zhvillua nga Arbër Kadriu and Alim Khowaja",

    "do you want to update": "A dëshironi ti përditësoni të dhënat?",
    "confirm deactivation":
      "Konfirmo se dëshironi të deaktivizoni llogarinë tuaj!",
    "your data": "Të dhënat e juaja",
    "no photo": "Nuk keni foto",
    "remove photo": "Largoni foton",
    "Set a photo": "Cakto nje foto per profilin tend",
  },
  delete_post: "A dëshironi te fshini postimin?",
};
