/* eslint-disable no-sparse-arrays */

import React, { Component } from "react";
import PostService from "../services/PostService";
import { tokenExists, setJWT, getUserId } from "../services/TokenService";
import NotificationSystem from "react-notification-system";
import withRouter from "react-router-dom/withRouter";
import { ClipLoader } from "react-spinners";
import GoogleMapCustom from "../components/Map/GoogleMapCustom";
import GoogleMapService from "../services/GoogleMapService";
import { Button, FormControl, FormLabel } from "react-bootstrap";
import PreviewCardPost from "components/Card-Post/PreviewCardPost";
import { AiOutlinePlus } from "react-icons/ai";
import i18n from "../i18configuration/i18";
import CustomForm from "components/CustomForm/CustomForm";
import SmallImage from "components/SmallImage/SmallImage";
import { propertyInputs } from "data/property";
import { PostTypes } from "helpers/postTypes";

class AddPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMap: true,
      propertyType: "",
      postTypeId: 0,
      characteristicType: 'patundshmeri',
      index: 0,
      numAmenities: 1,
      lang: i18n.language,
      processing: false,
      processingButton: false,
      loadingPhoto: false,
      addressSelected: undefined,
      categoryItems: [
        { key: "1", value: "banese", text: "Banesë" },
        { key: "2", value: "shtepi", text: "Shtëpi" },
        { key: "3", value: "ville", text: "Villë" },
        { key: "4", value: "lokal", text: "Lokal" },
        { key: "5", value: "toke", text: "Tokë" },
        { key: "6", value: "zyre", text: "Zyrë" },
      ],
      previewPost: {
        brand : "",
        primaryPhoto: "",
        rent: false,
        selling: false,
        postDescription: [
          {
            icon: "",
            info: "",
          },
        ],
        description: "",
        title: "",
        photos: [
          {
            blobString: "",
          },
        ],
        neighborhood: "",
      },
      previewPostStyle: {
        postClass:
          "light-box-shadow bg-white m-auto height-90-vh m-vertical-15-px",
        postDescriptionClass: "flex flex-space-between",
      },
      InputGroup: propertyInputs
    };
  }


  componentDidMount() {
    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
  }

  componentDidUpdate() {
    if (this.state.previewPost) {
      var photosTemp = this.state.previewPost.photos;
      if (
        photosTemp &&
        photosTemp[0].blobString != ""
      ) {
        if (
          photosTemp.length == 1 &&
          photosTemp[0].blobString != this.state.previewPost.primaryPhoto
        ) {
          this.setState({
            previewPost: {
              ...this.state.previewPost,
              primaryPhoto: photosTemp[0].blobString,
              primaryPhotoExtension: photosTemp[0].extension,
            },
          });
        }
      }

      if (this.state.previewPost.brand) {
        let toHideElements = this.state.InputGroup.filter(input => input.inputName === 'kilometers')[0]
        if (typeof this.state.previewPost.brand === 'string' && this.state.previewPost.brand.replace('undefined,', '').split(',').length > 1 && toHideElements.hidden == false) {
          let hidden = true
          let changeVisibility = {
            kilometers: hidden,
            transmision: hidden,
            nrSeats: hidden,
            nrDoors: hidden,
            petrolType: hidden,
            placeOfOrigin: hidden,
            color: hidden,
            manufactureYear: hidden,
            model: hidden
          };
          this.setPropertyHiddenValue(changeVisibility, {});
        }
        else if (typeof this.state.previewPost.brand === 'string' && this.state.previewPost.brand.replace('undefined,', '').split(',').length <= 1 && toHideElements.hidden == true) {
          let hidden = false
          let changeVisibility = {
            kilometers: hidden,
            transmision: hidden,
            nrSeats: hidden,
            nrDoors: hidden,
            petrolType: hidden,
            placeOfOrigin: hidden,
            color: hidden,
            manufactureYear: hidden,
            model: hidden
          };

          this.setPropertyHiddenValue(changeVisibility, {});
        }
      }
    }
    if (this.state.updatedPost != undefined) {
      this.setState({
        previewPost: this.state.updatedPost,
        updatedPost: undefined,
      });
    }
    if (this.state.newInputSet != undefined) {
      this.setState({
        InputGroup: this.state.newInputSet,
        newInputSet: undefined,
      });
    }
  }
  notificationSystem = React.createRef();

  postService = new PostService();
  googleMapServivce = new GoogleMapService();

  validateAndPrepareData(data) {

    let color = data['color'];
    if(color != null && color != undefined && color != '' )
      data['color'] = color.join(',');

      let currency = data['currency'];
      if(Array.isArray(currency)){if(currency != null && currency != undefined && currency != '' )
      data['currency'] = currency.join(',');}
      else{
        console.error("currency is not an arrray");
      }
    
    

    let petrolType = data['petrolType'];
    if(petrolType != null && petrolType != undefined && petrolType != '')
      data['petrolType'] = petrolType.join(',');

    let transmision = data['transmision'];
    if(transmision != null && transmision != undefined && transmision != '')
      data['transmision'] = transmision.join(',');

    return data;

  }

  addPost = async () => {
    if (this.state.processing) return;
    this.setState({
      processing: true,
      processingButton: true,
    });

    var data = this.state.previewPost;
    var notification = this.notificationSystem.current;
    var obj = getUserId();
    data = {
      ...data,
      postType: {
        postTypeId: this.state.postTypeId,
        description: this.state.propertyType,
      },
      latitude:
        this.state.latitude != undefined && this.state.latitude != 0
          ? this.state.latitude
          : undefined,
      longitude:
        this.state.longitude != undefined && this.state.longitude != 0
          ? this.state.longitude
          : undefined,
      primaryPhoto: this.state.previewPost.primaryPhoto,
      userId: obj,
      showMap: this.state.showMap,
    };
    var verification = this.verifyData(data);

    data = this.trimData(data);
    if (!verification) {
      this.setState({
        processing: false,
        processingButton: false,
      });
      return;
    }

    data = this.validateAndPrepareData(data);
    this.postService
      .create(data)
      .then((res) => {
        setJWT(res.data.token);
        notification.addNotification({
          message: i18n.t("Posti u shtua me sukses!"),
          level: "success",
          position: "tc",
          autoDismiss: "5",
          dismissible: "both",
        });
        var type = data.cimere
          ? "input-cimere=true"
          : data.rent
            ? "input-rent=true"
            : "input-buy=true";
        setTimeout(() => {
          this.props.history.push("/user/post?" + type + "&page=1");
        }, 3000);
      })
      .catch((err) => {
        this.setState({
          processing: false,
          processingButton: false,
        });
        if (err.response.status == 401) {
          notification.addNotification({
            message: i18n.t("Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri."),
            level: "warning",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
        } else
          notification.addNotification({
            message:
              i18n.t("Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri."),
            level: "warning",
            position: "tc",
            autoDismiss: "5",
            dismissible: "both",
          });
      }).finally(() => {
        this.setState({
          processing: false,
        });
      });
  };
  makeSmallPhotoActive = (index) => {
    var containers = document.getElementsByClassName("small-image-container");

    for (var i = 0; i < containers.length; i++) {
      if (containers[i].classList.contains("active-image"))
        containers[i].classList.remove("active-image");
    }
    containers[index].classList.add("active-image");
  };

  trimData = (verifyData) => {
    verifyData.title = verifyData.title.trim();
    if (verifyData.description?.trim())
      verifyData.description = verifyData.description.trim();

    return verifyData;
  };
  verifyData = (verifyData) => {
    var notification = this.notificationSystem.current;
    var message = "";
    var type = verifyData.rent | verifyData.selling | verifyData.cimere;
    var priceRegex = /^\d+.{0,1}\d{0,2}$/;
    var regex = /^\d+$/;
    var spaceRegex = /^\d+(\.\d{1,3}){0,1}$/;
    if (verifyData.postType.postTypeId != PostTypes.Ahengje && !type) {
      message = "Ju lutem të caktoni tipin e postit.";
    }
    if (!verifyData.postType.postTypeId) {
      message = "Ju lutem të caktoni llojin e postit.";
    } else if (!verifyData.title) {
      message = "Ju lutem të caktoni titullin e postit.";
    } else if (verifyData.title.length > 250) {
      message =
        "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar " +
        verifyData.title.length +
        " karaktere";
    } else if ((![PostTypes.Ville, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !verifyData.space)) {
      message = "Ju lutem të caktoni hapësirën.";
    } else if (![PostTypes.Toke, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && (!verifyData.bathrooms)) {
      message = "Ju lutem të caktoni numrin e banjove.";
    } else if (![PostTypes.Toke, PostTypes.Lokal, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && (!verifyData.rooms)) {
      message = "Ju lutem të caktoni numrin e dhomave.";
    } else if (verifyData.postType.postTypeId == PostTypes.Vetura && verifyData.selling && !verifyData.price) {
      message = "Ju lutem të caktoni çmimin.";
    } else if (!verifyData["input-location"]) {
      message = "Ju lutem të caktoni qytetin/rajonin ku ndodhet.";
    } else if (
      !verifyData.photos ||
      verifyData.photos.length == 0
    ) {
      message = "Ju lutem të caktoni fotot për postin";
    } else if (!verifyData.primaryPhoto) {
      message =
        "Ju lutem të caktoni se cilën foto dëshironi ta keni të vendosur si foto kryesore";
    } else if (!verifyData["input-location"]) {
      message = "Ju lutem të caktoni qytetin/rajonin.";
    } else if ([PostTypes.Shtepi, PostTypes.Ville].includes(verifyData.postType.postTypeId) && !verifyData.kate) {
      message = "Ju lutem të caktoni numrin e kateve.";
    } else if ([PostTypes.Zyre, PostTypes.Lokal].includes(verifyData.postType.postTypeId) && !verifyData.floor) {
      message = "Ju lutem të caktoni katin ne te cilin ndodhet.";
    } else if ([PostTypes.Ville].includes(verifyData.postType.postTypeId) &&
      (!verifyData.numberOfPeopleAllowed ||
        verifyData.numberOfPeopleAllowed < 1)
    ) {
      message = "Ju lutem të caktoni numrin e personave të lejuar.";
    } else if (
      ![PostTypes.Ville, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !spaceRegex.test(verifyData.space)
    ) {
      message =
        "Nuk lejohet të shënohet shkronjë ne hapësirën e numrave për metër katror!";
    } else if (
      ![PostTypes.Toke, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !regex.test(verifyData.bathrooms)
    ) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e banjove!";
    } else if (![PostTypes.Toke, PostTypes.Lokal, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !regex.test(verifyData.rooms)) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e dhomave!";
    } else if (verifyData.postType.postTypeId === PostTypes.Vetura && verifyData.selling && !priceRegex.test(verifyData.price)) {
      message =
        "Çmimi duhet të jetë i formatit numër apo numër me dy vlera pas presjes dhjetore!";
    } else if (verifyData.postType.postTypeId === PostTypes.Vetura && verifyData.rent && !priceRegex.test(verifyData.dailyPrice)) {
      message =
        "Çmimi duhet të jetë i formatit numër apo numër me dy vlera pas presjes dhjetore!";
    }

    if (message != "") {
      notification.addNotification({
        message: message,
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return false;
    }
    return true;
  };

  createPostDescription = (previewPost) => {
    var postTemp = previewPost;
    var postDescription = [];
    postTemp = {
      ...postTemp,
      postDescription: postDescription,
      primaryPhoto: this.state.previewPost.primaryPhoto,
      primaryPhotoExtension: this.state.previewPost.primaryPhotoExtension,
      neighborhood: this.state.previewPost.neighborhood,
    };
    if (postTemp) {
      if (postTemp.rooms && postTemp.rooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "bedroom",
            info: postTemp.rooms + " rooms",
          },
        ];

      if (postTemp.bathrooms && postTemp.bathrooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "shower",
            info: postTemp.bathrooms + " Baths",
          },
        ];

      if (postTemp.space && postTemp.space > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "space",
            info: postTemp.space + postTemp.unit,
          },
        ];

      this.setState({
        previewPost: postTemp,
        needDescCreated: false,
      });
    }
  };

  previewData = (data) => {
    this.createPostDescription(data);
  };

  photoLoad = (data, ended, double) => {
    if (ended) {
      this.setState({
        loadingPhoto: false,
      });
    }
    var notification = this.notificationSystem.current;
    if (double) {
      notification.addNotification({
        message:
          i18n.t("Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
    if (data.length > 15) {
      notification.addNotification({
        message:
          i18n.t("Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }
    this.setState({
      previewPost: {
        ...this.state.previewPost,
        photos: data,
      },
    });
  };

  propertyTypeClicked = (e) => {
    var propertyType = e.target.value;
    var propertyValue = 0;
    var kateHidden = true;
    var floorNum;
    var dhomaZyre; //change text
    var banjoHidden = false;
    var dhomaHidden = false;
    var hapesiraAri;
    var kateText;
    var fletaPosedueseHidden = false;
    var NumberOfPeopleAllowedHidden = true;
    var shitjeHidden = false;
    var QeraHidden = false;
    var cimereHidden = false;
    var amenitiesHidden = false;
    var cmimiText = 'Çmimi*'


    //gastronomi

    var kafeHidden = true;
    var dasmaHidden = true;
    var loungeHidden = true;
    var spaceHidden = false;
    var partyHidden = true;
    var muzikeHidden = true;
    var ushqimHidden = true;

    //vetura
    var brand = true;
    var model = true;
    var color = true;
    var petrolType = true;
    var manufactureYear = true;
    var kilometers = true;
    var transmision = true;
    var nrSeats = true;
    var nrDoors = true;
    var placeOfOrigin = true;
    var customsChecked = true;
    var dailyPriceHidden = true

    if (propertyType == "shtepi" || propertyType == "patundshmeri") {
      propertyValue = PostTypes.Shtepi;
      kateHidden = false;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "cimere") {
      propertyValue = PostTypes.Cimere;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "banese") {
      propertyValue = PostTypes.Banese;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "zyre") {
      propertyValue = PostTypes.Zyre;
      kateHidden = true;
      dhomaZyre = true; //change text
      floorNum = false;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "toke") {
      propertyValue = PostTypes.Toke
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Ari (m2)*";
    } else if (propertyType == "lokal") {
      propertyValue = PostTypes.Lokal;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = false;
      dhomaHidden = true;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
    } else if (propertyType == "ville") {
      propertyValue = 7;
      kateHidden = false;
      dhomaZyre = false;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      spaceHidden = true
    } else if (propertyType == "gastronomi") {
      propertyValue = PostTypes.Ahengje;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      shitjeHidden = true;
      QeraHidden = true;
      cimereHidden = true;
      dasmaHidden = false;
      loungeHidden = false;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = false;
      muzikeHidden = false;
      ushqimHidden = false;
      kafeHidden = false;
    } else if (propertyType == "vetura") {
      propertyValue = PostTypes.Vetura;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = true;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      fletaPosedueseHidden = true;
      shitjeHidden = false;
      QeraHidden = false;
      cimereHidden = true;
      dasmaHidden = true;
      loungeHidden = true;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = true;
      muzikeHidden = true;
      ushqimHidden = true;
      kafeHidden = true;
      brand = false;
      model = false;
      color = false;
      customsChecked = false;
      petrolType = false;
      manufactureYear = false;
      kilometers = false;
      transmision = false;
      nrSeats = false;
      nrDoors = false;
      placeOfOrigin = false;
      dailyPriceHidden = false;
      cmimiText = 'Çmimi mujor (opsional)'
    }
    var changeVisibility = {
      party: partyHidden,
      kafe: kafeHidden,
      muzike: muzikeHidden,
      ushqim: ushqimHidden,
      bathrooms: banjoHidden,
      space: spaceHidden,
      unit: spaceHidden,
      kate: kateHidden,
      floor: floorNum,
      rooms: dhomaHidden,
      fletePoseduese: fletaPosedueseHidden,
      numberOfPeopleAllowed: NumberOfPeopleAllowedHidden,
      qera: QeraHidden,
      selling: shitjeHidden,
      cimere: cimereHidden,
      dasma: dasmaHidden,
      lounge: loungeHidden,
      amenities: amenitiesHidden,
      brand,
      model,
      color,
      petrolType,
      manufactureYear,
      kilometers,
      transmision,
      nrSeats,
      nrDoors,
      placeOfOrigin,
      dailyPrice: dailyPriceHidden
    };
    var changeText = {
      rooms: dhomaZyre,
      space: hapesiraAri,
      kate: kateText,
      price: cmimiText
    };
    var type = propertyType || "patundshmeri"
    var temp = this.state.InputGroup.map(inp => {
      if (inp.type == 'tagcreator')
        inp.itemTypes = type
      return inp
    })
    this.setState({

      characteristicType: type,
      InputGroup: temp
    })
    this.setPropertyHiddenValue(changeVisibility, changeText);

    this.setState({
      postTypeId: propertyValue,
      propertyType: propertyType,
    });
  };

  setPropertyHiddenValue = (changeVisibility, changeText) => {
    var inputG = this.state.InputGroup;
    var keys = Object.keys(changeVisibility);
    var value = Object.values(changeVisibility);
    var temp;
    //change visibility
    for (var i = 0; i < keys.length; i++) {
      temp = inputG.filter((obj) => {
        if (obj.inputName != keys[i] && obj.namingProperty != keys[i])
          return obj;
      });
      var objAt = 0;
      var tempObj = inputG.filter((obj, index) => {
        if (
          obj.inputName == keys[i] ||
          (obj.namingProperty != undefined && obj.namingProperty == keys[i])
        ) {
          objAt = index;
          return obj;
        }
      });

      tempObj = tempObj[0];
      tempObj = {
        ...tempObj,
        hidden: value[i],
      };

      temp.splice(objAt, 0, tempObj);
      inputG = temp;
    }
    keys = Object.keys(changeText);
    value = Object.values(changeText);
    //change text
    for (var i = 0; i < keys.length; i++) {
      temp = inputG.filter((obj) => {
        if (
          obj.inputName != keys[i] ||
          (obj.namingProperty != undefined && obj.namingProperty != keys[i])
        )
          return obj;
      });
      var objAt = 0;
      var tempObj = inputG.filter((obj, index) => {
        if (
          obj.inputName == keys[i] ||
          (obj.namingProperty != undefined && obj.namingProperty == keys[i])
        ) {
          objAt = index;
          return obj;
        }
      });
      tempObj = tempObj[0];
      tempObj = {
        ...tempObj,
        label: {
          ...tempObj.label,
          text: value[i],
        },
      };

      temp.splice(objAt, 0, tempObj);
      inputG = temp;
    }

    this.setState({
      InputGroup: temp,
    });
  };

  getClass = (cls) => {
    var classN =
      "bg-blue white no-border font-18 add-listing-button radio-size ";
    if (cls == this.state.propertyType) classN += " active-button";
    return classN;
  };

  changeImage = (e, receivedIndex, image) => {
    if (e != null) e.preventDefault();
    this.setState({
      index: receivedIndex,
      previewPost: {
        ...this.state.previewPost,
        primaryPhoto: image.blobString,
        primaryPhotoExtension: image.extension,
      },
    });

    this.makeSmallPhotoActive(receivedIndex);
  };

  loadingPhoto = (type) => {
    this.setState({
      loadingPhoto: true,
    });
  };

  coordinatesSet = async (obj) => {
    this.setState({
      latitude: obj.lat,
      longitude: obj.lng,
    });

    await this.googleMapServivce
      .getAddress(obj.lat, obj.lng)
      .then((res) => {
        var objs = [];
        if (res?.data?.results.length > 0) {
          objs = res.data.results[0].address_components.filter(obj => {
            if (obj.types.includes('route') || obj.types.includes('sublocality')) return obj;
          })
          let neighborhood = "";
          let city = "";
          if (objs.length > 1) {
            neighborhood = objs.filter(item => {
              if (item.types.includes('route'))
                return item
            })[0].long_name
            city = objs.filter(item => {
              if (item.types.includes('sublocality'))
                return item
            })[0].long_name
          }
          else if (objs.length > 0) {
            city = objs.filter(item => {
              if (item.types.includes('sublocality'))
                return item
            })[0].long_name
          }
          this.setState({
            previewPost: {
              ...this.state.previewPost,
              neighborhood
            },
          });
        }
      })
  };
  deleteImage = (imageBlob) => {
    var photosArray = this.state.previewPost.photos.filter((obj) => {
      if (obj.blobString != imageBlob) return obj;
    });
    var tempPreview = this.state.previewPost;
    if (photosArray.length == 0) {
      photosArray.push({
        blobString: "",
      });
      document.getElementsByClassName("file-input")[0].value = "";
    }

    tempPreview = {
      ...tempPreview,
      photos: photosArray,
    };
    this.setState({
      updatedPost: tempPreview,
    });
  };
  facebookLinkVisibilityChanged = (inputName) => {
    var facebookLinkHidden = this.state.postTypeId === PostTypes.Vetura || ["rent", 'selling', "dasma", "ushqim", 'kafe', 'muzike', 'party'].includes(inputName);
    var customsCheckedHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'selling')
    var nrSeatsHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'rent')
    var nrDoorsHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'rent')
    var changeVisibility = {
      facebookLink: facebookLinkHidden,
      nrSeats: nrSeatsHidden,
      nrDoors: nrDoorsHidden,
      customsChecked: customsCheckedHidden
    };
    var changeText = {};
    this.setPropertyHiddenValue(changeVisibility, changeText);
  };
  hideMapChanged = (e) => {
    this.setState({
      showMap: !e.target.checked,
    });
  };
  cityChosen = (lat, lng) => {
    if (this.state.latitude != undefined && this.state.latitude != "")
      this.setState({
        latitude: lat,
        longitude: lng,
      });

    this.setState({
      citySetLatitude: lat,
      citySetLongitude: lng,
    });
  };

  getClassNameT = (num) => {
    var cls = "main-button";
    if ((![PostTypes.Ahengje, PostTypes.Vetura].includes(this.state.postTypeId) && num == -1) || this.state.postTypeId == num) cls += " active-button-add";

    return cls;
  };

  notImplementedYet = () => {
    var notification = this.notificationSystem.current;
    notification.addNotification({
      message: i18n.t("Se shpejti!"),
      level: "info",
      position: "tc",
      autoDismiss: "5",
      dismissible: "both",
    });
  };
  doNothing = () => { };
  render() {
    const previewPost = this.state.previewPost;
    const mapLangs = {
      'en': 'EN',
      'al': 'SQ',
      'al_region': 'ALB',
      'en_region': 'EN'
    }
    return (
      <div className="">
        <NotificationSystem ref={this.notificationSystem} />
        {/* <SearchBar /> */}\
        <div className="flex main-add-post">
          <div className="flex vertical-flex w-100 main-shadow">
            <div className="flex blue-underline">
              <button
                className={this.getClassNameT(-1)}
                value={"patundshmeri"}
                onClick={this.propertyTypeClicked}
              >
                {i18n.t('real estate')}

              </button>
              <button
                className={this.getClassNameT(PostTypes.Ahengje)}
                value={"gastronomi"}
                onClick={this.propertyTypeClicked}
              >
                {i18n.t('gastronomi')}

              </button>
              <button
                className={this.getClassNameT(PostTypes.Vetura)}
                onClick={this.propertyTypeClicked}
                value={'vetura'}
              >
                {i18n.t('cars')}

              </button>

            </div>
            <div className="flex flex-space-around w-90 m-2-perc wrap post-type-button-wrapper ">
              {![PostTypes.Ahengje, PostTypes.Vetura].includes(this.state.postTypeId) && (
                <>
                  <div className="margin-side-5-px flex align-items-center text-align-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"shtepi"}
                      className={this.getClass("Shtëpi")}
                      onClick={this.propertyTypeClicked}
                    />

                    <label className="ml-5-px m-0 label-padding">{i18n.t('home')}
                    </label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"banese"}
                      className={this.getClass("Banesë")}
                      onClick={this.propertyTypeClicked}
                    />

                    <label className="ml-5-px label-padding">{i18n.t('apartment')}
                    </label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"toke"}
                      className={this.getClass("Tokë")}
                      onClick={this.propertyTypeClicked}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('land')}
                    </label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      value={"zyre"}
                      name="post-type"
                      className={this.getClass("Zyrë")}
                      onClick={this.propertyTypeClicked}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('office')}
                    </label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      value={"lokal"}
                      name="post-type"
                      className={this.getClass("Lokal")}
                      onClick={this.propertyTypeClicked}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('local')}
                    </label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"ville"}
                      className={this.getClass("Villë")}
                      onClick={this.propertyTypeClicked}
                    />
                    <label className="ml-5-px m-0 text-center text-align-center label-padding">
                      {i18n.t('villa')}

                    </label>
                  </div>
                </>
              )}
            </div>
            {![PostTypes.Ahengje, PostTypes.Vetura].includes(this.state.postTypeId) ? <div className="hr-line"></div> : ""}
            <div className="w-90 m-2-perc">
              <CustomForm
                previewData={this.previewData}
                loadingPhoto={this.loadingPhoto}
                photoLoaded={this.photoLoad}
                handleSubmit={this.addPost}
                facebookLinkVisibilityChanged={this.facebookLinkVisibilityChanged}
                formclass="align-center flex  w-100 wrap"
                data={this.state.InputGroup}
                cityChosen={this.cityChosen}
                characteristicType={this.state.characteristicType}
              />
            </div>

            {previewPost &&
              previewPost != {} &&
              previewPost.photos != undefined &&
              previewPost.photos[0].blobString != "" ? (
              <h3 className="small-image flex m-vertical-10-px">
                {i18n.t('main photo')}
              </h3>
            ) : (
              ""
            )}
            <div className="small-image flex m-vertical-10-px wrap flex-center align-center">
              {previewPost &&
                previewPost != {} &&
                previewPost.photos != undefined &&
                previewPost.photos[0].blobString != ""
                ? previewPost.photos.map((image, index) => {
                  return (
                    <span
                      key={index}
                      onClick={(e) => this.changeImage(e, index, image)}
                    >
                      <SmallImage
                        deleteImage={this.deleteImage}
                        imageKey={index}
                        Image={image}
                        Alternative={"this is image " + index}
                      />
                    </span>
                  );
                })
                : ""}

              {this.state.loadingPhoto ? (
                <ClipLoader color="blue" className="loader" />
              ) : (
                ""
              )}
            </div>
            <div className="m-3 p-3 background-warning">
              <FormLabel className="red m-0">
                {i18n.t('select neighborhood')}
              </FormLabel>
            </div>
            <div className="m-2-perc relative ">
              <label htmlFor="" className="height-5-vh">
                {i18n.t('select location')}
              </label>

              <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3 ">
                <GoogleMapCustom
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&region=US&language=EN&libraries=places,geometry,drawing&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  loadingElement={<div style={{ height: "95%" }} />}
                  containerElement={<div style={{ height: "100%" }} />}
                  mapElement={<div style={{ height: "95%", width: "95%" }} />}
                  coordinatesSet={this.coordinatesSet}
                  clickMapForMarker={true}
                />
              </div>
              <div className="flex align-center">
                <FormLabel className="red m-0">
                  {i18n.t('do not show map')}
                </FormLabel>
                <FormControl
                  checked={!this.state.showMap}
                  className="show-map-checkbox"
                  onChange={this.hideMapChanged}
                  style={{ marginTop: "0px !important" }}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          <div className="inherit-all flex vertical-flex align-center separate-line sticky-pos">
            <h3>{i18n.t('preview')}</h3>
            <PreviewCardPost
              postStyle={this.state.previewPostStyle}
              propertyType={this.state.propertyType}
              post={previewPost}
              showPrice={true}
            />
          </div>
        </div>
        <div className="flex flex-center align-center add-post-div m-vertical-15-px">
          <Button
            disabled={this.state.processingButton}
            onClick={this.addPost}
            className="bg-blue custom-button align-center flex flex-center relative"
          >
            <AiOutlinePlus className="add-button-plus-icon" size={25} />
            {i18n.t('add post')}
          </Button>
          {this.state.processing ? (
            <ClipLoader color="blue" className="loader" />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(AddPost);
