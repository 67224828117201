/* eslint-disable import/no-anonymous-default-export */
export default {
  translation: {
    albanian: "Albanian",
    english: "English",
    "select language": "Change language",
    dashboard: "Dashboard",
    "find it": "How you ask for it, you find it!",
    "all types":
      "All types of REAL ESTATE for rent or sale, the best RESTAURANTS as well as CARS for rent and sale in Ontrine.",
    "about us": "About us",
    "real estate": "Property",
    gastronomi: "Restaurants",
    restaurantType: "Restaurant Type",
    cars: "Cars",
    rent: "Rent",
    buy: "Buy",
    contactus: "Contact us",
    "room mate": "Room mate",
    "find city": "Find yout city/neighborhood",
    "post type": "Post type",
    price: "Price",
    min: "min",
    max: "max",
    search: "Search",
    "quick solutions": "We offer quick selection for you!",
    "real estate as you like": "House or flat as you are looking for",
    "your preferences": "You can find your preferences with us",
    "best gastronomy": "The best restaurants in New York",
    "gastronomy types": "Different categories of gastronomy",
    "fast find": "Quick search for comfortable driving",
    "rent buy cars": "Cars for rent or sale",
    "find quick": "You find it very quickly",
    "find within 5 minutes":
      "Within 5 minutes you will find what you are looking for",
    "best apartments": "The best apartments",
    "best offers": "We bring you the best offers.",
    "visit all": "Visit all.",
    "ad space": "Space for advertising",
    "building space ad": "This space is for advertising buildings.",
    "contact us at":
      "Contact us at phone number (347) 628-4593 or at contact@ontrine.com",
    "guide map": "Site map",
    contact: "Contact",
    manage: "Manage",
    "my posts": "My posts",
    account: "Account",
    logout: "Log out",
    login: "Log in",
    "Numri i kateve te shtepise*": "Number of floors*",
    "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*": "Rooms",
    "new user": "You are new to the Ontrine site",
    register: "Sign up",
    "write email here": "Enter your email here...",
    "write pass here": "Enter the password here...",
    "reset pass": "Reset password",
    "create account": "Create an account and become part of Ontrine",
    "you have account": "Do you have an account?",
    "login here": "Click here",

    people: "Person",
    "car model": "Car model",
    "add post": "Add post",

    home: "Home",
    apartment: "Apartment",
    land: "Land",
    office: "Office",
    villa: "Log Cabins",
    "Postimet per Kerkim": "Posts for you search",
    "searching posts": "Posts for you search",
    "Postimet per qera": "Posts for rent",
    "Postimet per shitje": "Posts for selling",
    no_posts_found: "No posts were found for your search",
    "contact owner": "Contact the owner",
    local: "Store",
    food: "Food",
    coffee: "Coffee",
    weddings: "Weddings",
    music: "Music  (Party)",
    "owning paper": "Owning paper",
    "upload photo": "Upload photos",
    "select neighborhood":
      "Please specify the correct neighborhood below so that your post can be found faster. Below you have the option to hide the map from your post.",
    "select location": "Set the location on the map:",
    "do not show map": "Do not show the map in the post",
    "add this post": "Add post",
    preview: "Preview",
    "chosen photo": "The selected photo is placed here",
    "chosen title": "The title is placed here",
    "posted on": "Posted on",
    month: "Month",
    day: "Day",
    "main photo": "Set main photo for your post*",

    details: "Details",

    //postdetails page
    general: "General Information",
    description: "Description",
    extra: "Extra",
    map: "Location",
    send: "Send",
    "contact owner": "Contact owner",
    "Emri..": "Name",
    "Mbiemri..": "Last Name",
    "Numri telefonit..": "Phone number",
    "Mesazhi..": "Message",
    delete: "Delete",
    back: "Back",
    "click to zoom": "Click to zoom",
    update: "Update",
    "make active": "Make active",
    archive: "Archive post",
    "one room": "One room",
    seats: "Seats",
    "manufactured at": "Manufactured at",
    doors: "Doors",
    transmission: "Transmission",
    petrol: "Petrol type",
    colors: "Colors",
    bathrooms: "Bathrooms",
    bathroom: "Bathroom",
    "owning letter": "Owning letter",
    "fb contact": "Contact on facebook",
    "in place deal": "In place deal",
    agency: "Agency",
    email: "Email",
    "Emri*:": "Name*:",
    "Mbiemri*:": "Last Name*:",
    "Emri agjensionit:": "Agency name:",
    Agjension: "Agency",
    "Emri*": "Name*",
    "Mbiemri*": "Last Name*",
    "Emri agjensionit": "Agency name",
    "Numri telefonit*": "Phone number",
    Përditëso: "Update",
    "Ndrysho fjalëkalimin": "Change password",
    "Fshij llogarinë": "Delete account",
    Qyteti: "City",
    "Linku ne facebook:": "Facebook link:",
    "Email*:": "Email*:",
    email_not_saved_anywhere:
      "Your email will not be public. Please fill the fields below.",
    "Fjalekalimi*:": "Password*:",
    follow_us: "Follow us",
    "Konfirmo fjalekalimin*:": "Confirm Password*:",
    "Telefoni:": "Phone:",
    Regjistrohu: "Register",
    name_placeholder: "Write your name...",
    lastname_placeholder: "Write your last name...",
    agencyname_placeholder: "Write your agency name...",
    link_placeholder: "Write your facebook link...",
    confirm_pass_placeholder: "Confirm password...",
    phone_placeholder: "Write your phone...",
    message_placeholder: "Write your message here...",
    "Email:": "Email:",
    "Fjalëkalimi:": "Password:",
    email_placeholder: "Write email here...",
    pass_placeholder: "Write password here...",
    "fb link": "Facebook link",
    "posted at": "Posted at",
    Garsoniere: "Studio",
    Dërgo: "Send",
    Qera: "Rent",
    Shitet: "Sell",
    "Cimer/e": "Roommate",
    "Kontakto Agjentin": "Contact Agent",
    "Kontakto Pronarin": "Contact Owner",
    "Kontakto Restorantin": "Contact Restaurant",
    "Upload photos": "Upload photos",
    "chose up to 15": "Choose up to 15 photos",
    "chosen photo": "Chosen photo is put here",
    "chosen title": "Chosen title is put here",

    fun: "Fun",
    Qera: "Rent",
    Blej: "Buy",
    Shitje: "Sale",
    "Cimer/e": "Roommate",
    "Lloji i postimit": "Post type",
    Banesë: "Apartment",
    Shtëpi: "house",
    Zyrë: "Office",
    Tokë: "Land",
    Villë: "Log Cabins",
    Banese: "Apartment",
    Shtepi: "House",
    Zyre: "Office",
    Toke: "Land",
    Ville: "Log Cabins",
    Lokal: "Cafeteria",
    Ahengje: "Celebrations",
    Çmimi: "Price",
    Dasma: "Weddings",
    Party: "Party",
    Ushqim: "Food",
    Kafe: "Coffee",
    Personat: "People",
    "Modeli vetures": "Car model",
    "Muzikë (Party)": "Music (Party)",
    "Titulli i postimit*": "Post Title*",
    Currency: "Currency",
    "Currency*": "Currency",
    Prona: "Type",
    "Qyteti/Rajoni*": "City/Region*",
    "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)":
      "Brand* (if you have more than one car, click all the brands you own)",
    "Marka*": "Brand*",
    "Hapësira (m2)*": "Space (sqft)*",
    "Hapësira (m2)": "Space (sqft)",
    "Hapësira m2 (vendosni vetem numrin)*":
      "Space sqft (only enter the number)*",
    "Modeli*": "Model*",
    "Modeli vetures*": "Car model*",
    "Transmisioni*": "Transmission*",
    "Ngjyra vetures*": "Car color*",
    "Ngjyra*": "Color*",
    "Karburanti*": "Fuel*",
    "Lloji karburantit*": "Fuel Type*",
    "Viti prodhimit*": "Year of manufacture*",
    "Kilometrazha*": "Mileage*",
    "Numri dyerve*": "Number of doors*",
    "Numri uleseve*": "Number of seats*",
    "Vendi origjines*": "Place of origin*",
    "Banjo*": "Bathroom*",
    "Numri kateve te shtepise*": "Number of floors of the house*",
    'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*':
      'Room*(Put 0 if you want to figure "Studio")*',
    "Dhoma*": "Room*",
    "Linku i profilit ne facebook": "(optional)",
    "Numri i katit (vendos 0 për përdhesë)*":
      "Floor number (set 0 for ground floor)*",
    "Numri personave te lejuar*": "Number of persons allowed*",
    "Fletë poseduese*": "Flete poseduese*",
    "Flete poseduese*": "Flete poseduese*",
    "I doganuar*": "Customs cleared*",
    Përshkrimi: "Description",
    "Ari (m2)*": "Acre",
    "Njesia matese": "Unit of measure",
    "Flete poseduese*": "Owning document*",
    Karakteristikat: "Characteristics",
    "Çmimi* (Vendos 0 nëse dëshironi të figuroj Me marrëveshje)":
      'Price* (Enter 0 if you want to show "Negotiate")',
    "Çmimi*": "Price",
    "Çmimi ditor*": "Daily price",
    "Çmimi ditor": "Daily price",
    "Zgjedh deri ne 15 foto*": "Choose up to 15 photos",
    "Posti u shtua me sukses!": "Post successfully added!",
    "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.":
      "Your session has expired, please log in again.",
    "Ju lutem të caktoni llojin e postit.": "Please select the post type.",
    "Ju lutem të caktoni titullin e postit.": "Please select the post type.",
    "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar":
      "The title can have a maximum of 250 characters. You have marked",
    "Ju lutem të caktoni hapësirën.": "Please set the space.",
    "Ju lutem të caktoni numrin e banjove.":
      "Please specify the number of bathrooms.",
    "Ju lutem të caktoni numrin e dhomave.":
      "Please specify the number of rooms.",
    "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.":
      "There was a problem sending the data, please try again.",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "One of the selected images was a duplicate image, it was only taken once as a photo",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "You have selected more than the allowed number, the maximum is 15 photos",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "One of the selected images was a duplicate image, it was only taken once as a photo",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "You have selected more than the allowed number, the maximum is 15 photos",
    "Se shpejti!": "Soon!",
    "Kërko lokacionin..": "Search for location..",
    "Kërko qytetin/lagjen": "Search city/region",
    "Te gjitha": "All",
    Kërko: "Search",
    "Kërko veturën": "Search car",
    "Ju lutem te mbushni te dhenat": "",
    "Të dhënat janë gabim, provoni përsëri": "",
    ari: "sqmt",
    kat: "story",
    persona: "people",
    personë: "people",
    Përdhesë: "groundfloor",
    dhoma: "rooms",
    dhomë: "room",
    ulëse: "seats",
    dyer: "doors",
    banjo: "bathrooms",
    Viti: "year",
    kate: "stories",

    "Unit*": "Measurement unit*",

    Wifi: "Wifi",
    Parking: "Parking",
    "TV i mençur": "Smart TV",
    Depo: "Storehouse",
    "Nxemje qendrore": "Central heating",
    Klimë: "AC",

    "Loja për fëmijë": "Games for kids",
    Parking: "Parking",
    Tarasë: "Terrace",
    Klimë: "AC",
    "Porosi online": "Online ordering",

    //about us
    line1: "Who we are?",
    line2:
      "Welcome to our website, where we provide a comprehensive platform for advertising and discovering a wide range of properties, including cars available for rent or sale. Additionally, we facilitate the exploration of diverse gastronomic experiences.\n" +
      "As intermediaries, we connect sellers with buyers, landlords with tenants, as well as sellers and landlords with agents. Please note that we do not directly sell or rent flats/houses; instead, we act as facilitators in these transactions.\n" +
      "\n" +
      "Our services encompass the publication of property sales and car rentals, allowing for efficient and effective transactions. Moreover, we offer an extensive range of advertising options for restaurants and bars, helping you discover new culinary delights.\n" +
      "Feel free to explore our website and take advantage of the opportunities we provide in the realm of property, cars, and gastronomy.",
    line3: "also to find all kinds of gastronomy!",
    line4:
      "We are not an agent and we do not sell/rent flats/houses, we are mediators.",
    line5:
      "Intermediary between sellers and buyers, landlords and tenants, also sellers with agent, landlords with agent!",
    line6: "We offer the publication of sales and the release of car rentals.",
    line7: "We also offer advertising/finding of various restaurants and bars.",
    line8: "What do we offer: ",
    line9:
      "Rental Properties: Explore a diverse selection of apartments and houses available for rent, tailored to your preferences and needs.",
    line10:
      "Properties for Sale: Discover a wide range of flats and houses for sale, offering opportunities for homeownership or real estate investments.",
    line11:
      "Car Rentals: Find the perfect vehicle for your needs through our reliable car rental service, ensuring convenient transportation options.",
    line12:
      "Cars for Sale: Browse through our inventory of cars available for purchase, catering to different budgets and preferences.",
    line13:
      "Gastronomy: Immerse yourself in the world of gastronomy with our comprehensive offerings.",
    line14:
      "Restaurants and Bars: Discover a variety of restaurants and bars, ranging from cozy cafes to fine dining establishments, all at your fingertips.",
    line15: "Announced by:",
    line16:
      "Property Owners or Real Estate Agents: Listings on our website are announced by property owners who wish to sell or rent their properties, as well as licensed real estate agents representing clients in the real estate market.",
    line17:
      "Restaurants: Discover a wide array of dining options that are announced directly by the restaurants themselves, allowing you to explore their unique offerings and ambiance.",
    line18:
      "Car Rental Companies, Dealerships, or Individual Owners: Our car rental section features announcements from reputable car rental companies, dealerships, as well as individual owners, ensuring a reliable and hassle-free rental experience.",
    line19: "Our Purpose:",
    line20:
      "At our website, our purpose is to provide you with faster and more convenient solutions for buying and selling, tailored to your preferences and convenience.",
    line22:
      "We aim to empower you to make buying or selling decisions on your terms, allowing you the freedom to choose what you want and where you want it. Our platform is designed to streamline the process, saving you time and effort while ensuring a seamless experience.",
    line21:
      "The site was designed by Adonis Rexha, Logo by Fatos Rexhepi and developed by Arbër Kadriu and Alim Khowaja",

    "do you want to update": "Do you want to update your data?",
    "confirm deactivation": "Confirm that you want to deactivate your account!",
    "your data": "Your data",
    "no photo": "You have no photo",
    "remove photo": "Remove your photo",
    "Set a photo": "Set a photo for your profile",

    Benzine: "Gas",
    Nafte: "Diesel",
    Hibrid: "Hybrid",
    Manual: "Manual",
    Automatik: "Automatic",
    "Semi Automatik": "Semi Automatic",
    "Të gjitha": "All",
    "E kuqe": "Red",
    "E kalter": "Blue",
    "E zeze": "Black",
    "E argjend": "Silver",
    "E hirt": "Gray",
    "E bardhë": "White",
    "E vjollcë": "Purple",
    "E pembe": "Pink",
    "E gjelbër": "Green",
    "E verdhë": "Yellow",
    muaj: "month",
    "/muaj": "/month",
    "/ditë": "/daily",
    Dhoma: "Rooms",
    Navigacion: "Navigation",
    Sensor: "Sensor",
    Airbag: "Airbag",
    ABS: "ABS",
  },
  delete_post: "Do you want to delete this post?",
};
