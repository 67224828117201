
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18configuration/i18";
import logo from '../../assets/img/image1.png'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // deviceWidth: 0,
            loginPage: false
        }
    }
    componentDidMount() {
        // this.setResponsiveDesign();
    }

    InputGroup = [
        {
            label: {
                text: "icon",//if empty the label wont show (it is defined in custom form)
                class: "bg-white p-10 radius-top-right-4 radius-bottom-right-4 h-100 flex align-center light-gray clickable absolute right-0",
                icon: "Mail",
                iconClass: "font-20 blue"
            },
            inputClass: "font-18  m-0-important inherit-height radius-bottom-right-0 radius-top-right-0 no-border pr-10-perc radius-top-left-4 radius-bottom-left-4 w-100 black",
            type: "text",
            placeholder: "Shkruaj emailin tuaj...",
            disabled: false,
            inputName: "input-minimum",
            inputDiv: "h-100 w-100",
            formGroup: "w-100 m-0 relative"
        }
    ]

    getFooterClassName = () => {
        var cls = "height-50-vh bg-blue white w-100 h-450";
        if (window.location.pathname.match('user/login')) cls += " loginpage";
        return cls;
    }

    // setResponsiveDesign = () => {
    //     var deviceWidth = window.innerWidth;
    //     this.setState({
    //         deviceWidth: deviceWidth,
    //     })
    // }

    searchingClicked
    render() {
        const footerClassName = this.getFooterClassName();
        return (
            <footer className={footerClassName}>

                <div className="w-80 h-80 relative top-10 left-10 flex flex-space-between footer-wrapper">
                    <div className="left-side w-100 flex vertical-flex">
                        <div className="flex-grow-1 flex h-80 wrap">
                            <div className="svg-image w-15 h-40 bg-white rounded">
                               <img className='w-100' src={logo} />
                            </div>
                            <div className="w-25 flex vertical-flex flex-space-between h-inherit pl-3-perc sitemap">
                                <h3>{i18n.t('guide map')}</h3>
                                <div className='h-75 list-wrapper'>
                                    <ul className=' w-100 h-100 p-0 m-0 flex vertical-flex font-18 wrap list-footer'>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/dashboard">{i18n.t('dashboard')}</NavLink>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/post?input-property-type=Generic-RealEstate&page=1">{i18n.t('real estate')}</NavLink>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/post?input-property-type=Generic-fun&page=1">{i18n.t('gastronomi')}</NavLink>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/post?input-property-type=Generic-car&page=1">{i18n.t('cars')}</NavLink>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/about-us">{i18n.t('about us')}</NavLink>
                                        <NavLink color='white' className="m-3-px w-50 light-gray footer-links" to="/user/contact">{i18n.t('contact')}</NavLink>
                                    </ul>
                                </div>
                            </div>
                            <div className=" flex vertical-flex flex-space-between h-inherit pl-3-perc contact-us-footer">
                                <h3>{i18n.t('contact')}</h3>
                                <div className='h-75 '>
                                    <ul className='no-bulletpoints w-100 h-100 p-0 m-0 flex vertical-flex font-18 wrap contact-us-list-footer'>
                                        <li className="p-0 m-3-px w-50 light-gray footer-links clickable contact-us-links" > <a href="tel:003476284593"></a> (347) 628-4593</li>
                                        <li className="m-3-px contact-us-links w-50 light-gray footer-links clickable" ><a className="light-gray footer-links" href="mailto:contact@ontrine.com">contact@ontrine.com</a> </li>
                                        <li className="m-3-px w-50 light-gray footer-links clickable contact-us-links" ><NavLink className="light-gray footer-links" to="www.ontrine.com">www.ontrine.com</NavLink></li>
                                        <li className="m-3-px w-50 light-gray footer-links contact-us-links" ><a className="light-gray footer-links" href="https://www.facebook.com/Kulmi-kscom-107213544578389">Facebook</a></li>
                                        <li className="m-3-px w-50 light-gray footer-links contact-us-links" ><a className="light-gray footer-links" href="https://www.instagram.com/kulmiks/">Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div >

                            </div>
                        </div>
                        <div className='line-divider bg-white w-100 no-border left-0 '></div>
                        <div className="flex align-center font-15 light-gray ">
                            <span>&copy;2025 Ontrine.com</span>
                            {/* <span className='m-side-5-px'>-</span>
                            <span className='m-0'> Powered by Bee Software Solution</span> */}
                        </div>
                    </div>
                    {/* <div className="right-side w-25 flex vertical-flex h-100 flex-end padding-top-10">
                        <div className="h-90 flex vertical-flex flex-space-between wrap text-center ">
                            <h3 className="white stay-up-to-date">Stay Up to date</h3>
                            <p className='light-gray'>Subscribe to our newsletter to receive our weekly feed.</p>
                            <CustomForm formclass="flex-center h-20 footer-form" data={this.InputGroup} />
                        </div>

                    </div> */}
                </div>
            </footer>
        );
    }
}

export default Footer;
