import React, { Component } from "react";
// import CustomForm from '../CustomForm/CustomForm';
import withRouter from "react-router-dom/withRouter";
import NotificationSystem from "react-notification-system";
import { Dropdown, Menu } from "semantic-ui-react";
import {
  MdLocationOn,
  MdHome,
  MdAttachMoney,
  MdEuroSymbol,
  MdMail,
} from "react-icons/md";

import { BsFillPersonFill } from 'react-icons/bs'

import LocationsService from "../../services/LocationsService";
import * as storage from "../../services/storage";
import { USCitiesList } from "../../helpers/temp";
import { CarList } from "../../helpers/temp";
import CustomForm from "components/CustomForm/CustomForm";
import { AiFillCar } from "react-icons/ai";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import i18n from '../../i18configuration/i18'
import CustomLabel from "components/CustomLabel/CustomLabel";
import CustomInput from "components/CustomInput/CustomInput";
import { searchRealEstate } from "data/search";
import { searchGastro } from "data/search";
import { searchCars } from "data/search";

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeSearch: "patundshmeri",
      mainColor: 'blue',
      verticallyAligned: false,
      deviceWidth: 0,
      locations: [],
      lang: i18n.language,
      gatheredData: {},
      gastronomi: false,
      searchTipiPrones: "Prona",
      InputGroup: searchRealEstate,
      InputGroupGastronomi: searchGastro,
      InputGroupVetura: searchCars
    };
  }
  setResponsiveDesign = () => {
    var deviceWidth = window.innerWidth;
    if (deviceWidth != this.state.deviceWidth) {
      this.setState({
        deviceWidth: deviceWidth,
      });
      this.props.resizedDone();
    }
  };
  locationsService = new LocationsService();

  //checks if is 15 out of sync from last time
  //dateSync1,timeSync1 are time synced for ads set on dashboard
  //those are initialized on dashboard
  synced = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    var syncDate = storage.local.getItem("dateSync1");
    var timeDate = storage.local.getItem("timeSync1");

    if (syncDate == null || timeDate == null) return false;

    var now = new Date(
      date.split("-")[0],
      date.split("-")[1],
      date.split("-")[2],
      time.split(":")[0],
      time.split(":")[1],
      time.split(":")[2]
    );
    var syncedAt = new Date(
      syncDate.split("-")[0],
      syncDate.split("-")[1],
      syncDate.split("-")[2],
      timeDate.split(":")[0],
      timeDate.split(":")[1],
      timeDate.split(":")[2]
    );

    var diff = (syncedAt.getTime() - now.getTime()) / 1000;
    diff /= 60;
    var minutesSinceSync = Math.abs(Math.round(diff));

    if (minutesSinceSync > 15) return false;
    return true;
    // return false
  };
  addDefaultPlaces = (locations = []) => {
    var countryOptions = USCitiesList();

    //shto te gjitha qytetet pa lagje ne fund te listes
    for (var i = countryOptions.length - 1; i >= 0; i--) {
      if (locations.filter(location => location[0] === countryOptions[i].text).length === 0){
        locations.unshift([countryOptions[i].text, '']);
      }
    }
    return locations;
  };

  checkIfAvailable = () => {
    var tempObj = this.state.InputGroup.filter((obj) => {
      if (obj.inputName == "input-location") return obj;
    });
    tempObj = tempObj[0];
    for (var i = 0; i < tempObj.items.length; i++) {
      if (
        tempObj.items[i].value.includes(" - ") ||
        tempObj.items[i].text.includes(" - ")
      ) {
        return true;
      }
    }
    return false;
  };

  async componentWillMount() {
    var locations = storage.local.getItem("locations");
    locations = locations === 'undefined' ? undefined : locations
    if (!locations || !this.synced())
      await this.locationsService.getAllLocations().then((res) => {
        storage.local.removeItem("locations");
        locations = res.data.data;
        storage.local.setItem("locations", JSON.stringify(locations));
        this.setState({
          fetched: true,
          locations: locations,
          searchedLocations: locations,
        });
      });
    else {
      locations = locations && JSON.parse(locations) || [];
      this.setState({
        locations: locations,
        searchedLocations: locations,
        fetched: true,
      });
    }
  }

  componentDidMount() {
    this.setResponsiveDesign();
  }

  //unused
  updateVisualLocations = (locations) => {
    if (this.checkIfAvailable()) return;
    var temp = this.state.InputGroup.filter((obj) => {
      if (obj.inputName != "input-location") return obj;
    });

    var tempObj = this.state.InputGroup.filter((obj) => {
      if (obj.inputName == "input-location") return obj;
    });
    tempObj = tempObj[0];

    var citiesDuplicate = locations?.map((item, index) => {
      let city = item[0]
      return city;
    }) || [];
    var testCity = new Set(citiesDuplicate);
    citiesDuplicate = Array.from(new Set(testCity));


    var citiesOnly = citiesDuplicate.map((city, index) => {
      let tempObj = {
        key: 'c-' + index,
        value: city,
        text: city
      };
      return tempObj;
    });

    var items = locations?.map((item, index) => {
      // console.log(item)  
      let str = item[1].charAt(0).toUpperCase() + item[1].slice(1);

      let tempObj = {
        key: index,
        value: (item[1] ? str + " - " : '') + item[0],
        text: (item[1] ? str + " - " : '') + item[0],
      };
      return tempObj;
    }) || [];

    // value eshte '- {qyteti}' sepse pjesa e pare duhet te jete normalisht emri i lagjes, keto te dhena jane per kerkimin e te gjitha postimeve ne nje qytet, pa marr parasysh lagjen
    // var countryOptions = i18n.language == 'al' ? CitiesList(): USCitiesList();


    //shto te gjitha qytetet pa lagje ne fund te listes
    for (var i = citiesOnly.length - 1; i >= 0; i--) {
      items.unshift(citiesOnly[i]);
    }
    tempObj = {
      ...tempObj,
      items: items,
    };
    var test = new Set(items);
    items = Array.from(new Set(test));
    temp.splice(3, 0, tempObj);
    if (this.state.fetched == true) {
      this.setState({
        InputGroup: temp,
        fetched: undefined,
      });
    }
  };
  componentDidUpdate() {
    var locations = this.state.locations;

    // var res = window.innerWidth < 769 ? true : false;
    if (this.state.fetched == true) {
      this.updateVisualLocations(locations);
    }
    if (this.state.typed == true) {
      this.updateTypedLocations(this.state.searchedLocations);
    }

    if (!this.props.resized) {
      this.setResponsiveDesign();
    }
  }

  updateTypedLocations = (searchedLocations) => {
    // if(this.state.locations.length==)
    var temp = this.state.InputGroup.filter((obj) => {
      if (obj.inputName != "input-location") return obj;
    });

    var tempObj = this.state.InputGroup.filter((obj) => {
      if (obj.inputName == "input-location") return obj;
    });
    tempObj = tempObj[0];
    var items = searchedLocations.map((item, index) => {
      let str = item[1].charAt(0).toUpperCase() + item[1].slice(1);

      let tempObj = {
        key: index,
        value: str + " - " + item[0],
        text: str + " - " + item[0],
      };
      return tempObj;
    });

    // value eshte '- {qyteti}' sepse pjesa e pare duhet te jete normalisht emri i lagjes, keto te dhena jane per kerkimin e te gjitha postimeve ne nje qytet, pa marr parasysh lagjen
    var countryOptions = USCitiesList();

    //shto te gjitha qytetet pa lagje ne fund te listes
    for (var i = countryOptions.length - 1; i >= 0; i--) {
      items.unshift(countryOptions[i]);
    }
    tempObj = {
      ...tempObj,
      items: items,
    };
    var test = new Set(items);
    items = Array.from(new Set(test));
    temp.splice(3, 0, tempObj);
    if (this.state.fetched == true) {
      this.setState({
        InputGroup: temp,
        typed: undefined,
      });
    }
  };

  setVertical = () => {
    var formDiv = this.state.formDiv;
    formDiv += " absolute z-index-front right-100";
    this.setState({
      formDiv: formDiv,
    });
  };

  searchingClicked = (data) => {
    let params = this.defineSearchParams(data);
    let path = "/user/post" + params + "&page=1";
    if (window.location.pathname.match("/user/post/")) {
      this.props.search(data);
    } else {
      this.props.history.push(path);
    }
  };

  defineSearchParams = (data) => {
    var params = "?";
    var temp = "";
    if (data["input-location"] != undefined && data["input-location"] != "") {
      temp = "input-location=" + data["input-location"] + "&";
      params += temp;
    }
    if (
      data["input-neighborhood"] != undefined &&
      data["input-neighborhood"] != ""
    ) {
      temp = "input-neighborhood=" + data["input-neighborhood"] + "&";
      params += temp;
    }
    if (data["input-maximum"] != undefined && data["input-maximum"] != "") {
      temp = "input-maximum=" + data["input-maximum"] + "&";
      params += temp;
    }
    if (data["input-minimum"] != undefined && data["input-minimum"] != "") {
      temp = "input-minimum=" + data["input-minimum"] + "&";
      params += temp;
    }
    if (
      data["input-property-type"] != undefined &&
      data["input-property-type"] != ""
    ) {
      let temp = '';
      if (data["input-property-type"] === 'patundshmeri') {
        temp = 'input-property-type=Generic-RealEstate'
      }
      else if (['gastronomi', 'Gastronomi'].includes(data["input-property-type"])) {
        temp = 'input-property-type=Generic-fun'
      }
      else if (['vetura', 'Vetura'].includes(data["input-property-type"])) {
        temp = 'input-property-type=Generic-car'
      }
      else {
        temp = 'input-property-type=' + data["input-property-type"]
      }
      temp = temp + "&";
      params += temp;
    }
    if (data["input-buy"] != undefined && data["input-buy"] != "") {
      temp = "input-buy=true&";
      params += temp;
    }
    if (data["input-rent"] != undefined && data["input-rent"] != "") {
      temp = "input-rent=true&";
      params += temp;
    }
    if (data["input-cimere"] != undefined && data["input-cimere"] != "") {
      temp = "input-cimere=true&";
      params += temp;
    }
    if (data["dasma"] != undefined && data["dasma"] != "") {
      temp = "input-dasma=true&";
      params += temp;
    }
    if (data["kafe"] != undefined && data["kafe"] != "") {
      temp = "input-kafe=true&";
      params += temp;
    }
    if (data["party"] != undefined && data["party"] != "") {
      temp = "input-party=true&";
      params += temp;
    }
    if (data["ushqim"] != undefined && data["ushqim"] != "") {
      temp = "input-ushqim=true&";
      params += temp;
    }

    if (data["input-car-model"]) {
      temp = `input-car-model=${data["input-car-model"]}&`;
      params += temp;
    }
    if (this.state.typeSearch == 'vetura') {
      temp = `input-property-type=vetura&`;
      params += temp;
    }
    else if (this.state.typeSearch == 'gastronomi') {
      temp = `input-property-type=ahengje&`;
      params += temp;
    }
    else {
      temp = `input-property-type=Generic-RealEstate&`;
      params += temp;
    }
    params = params.slice(0, -1);
    return params;
  };

  doNothing() { }

  isLabelNeeded = (text) => {
    if (text == "" || text == "icon") return false;
    return true;
  };
  getClass = (input) => {
    var className = "search-input-checkbox-div flex  h-100 font-20 w-30 ";
    if (input.formGroup != undefined && input.formGroup != null)
      className += input.formGroup;
    return className;
  };

  getCheckboxesForCars = () => {
    var temp = this.state.InputGroupVetura.filter(
      (input) => input.type == "checkbox"
    );
    var anotherTemp = [];
    var inputDiv = "w-100 h-50 flex align-center checkboxes-responsive ";
    var inputClass = "w-100 m-0-important h-100 ";
    var i = 0;
    while (i < temp.length) {
      var post = temp[i];
      var postTemp = {
        ...post,
        inputDiv: inputDiv,
        inputClass: inputClass,
      };
      anotherTemp.push(postTemp);
      i++;
    }

    return (
      <div className="flex flex-space-between h-15">
        {anotherTemp.map((inp, index) => {
          return (
            <FormGroup key={index} className={this.getClass(inp)}>
              {this.isLabelNeeded(inp.label.text) ? (
                <CustomLabel key={index} data={inp.label} />
              ) : (
                ""
              )}
              <CustomInput
                handleFocus={this.doNothing}
                handleInput={this.handleInputChange}
                data={inp}
                value={inp.inputName == "total-price" ? inp.value : undefined}
              />
            </FormGroup>
          );
        })}
      </div>
    );
  }

  getCheckboxes = (gastronomi) => {
    var temp;
    if (gastronomi)
      temp = temp = this.state.InputGroupGastronomi.filter(
        (input) => input.type == "checkbox"
      );
    else
      temp = this.state.InputGroup.filter(
        (input) => input.type == "checkbox"
      );
    var anotherTemp = [];
    var inputDiv = "w-100 h-50 flex align-center checkboxes-responsive ";
    var inputClass = "w-100 m-0-important h-100 ";
    var i = 0;
    while (i < temp.length) {
      var post = temp[i];
      var postTemp = {
        ...post,
        inputDiv: inputDiv,
        inputClass: inputClass,
      };
      anotherTemp.push(postTemp);
      i++;
    }

    return (
      <div className="flex flex-space-between h-15">
        {anotherTemp.map((inp, index) => {
          return (
            <FormGroup key={index} className={this.getClass(inp)}>
              {this.isLabelNeeded(inp.label.text) ? (
                <CustomLabel key={index} data={inp.label} />
              ) : (
                ""
              )}
              <CustomInput
                handleFocus={this.doNothing}
                handleInput={this.handleInputChange}
                data={inp}
                value={inp.inputName == "total-price" ? inp.value : undefined}
              />
            </FormGroup>
          );
        })}
      </div>
    );
  };

  getAdressInput = () => {
    var items = this.state.InputGroup.filter((obj, index) => {
      if (index == 3) return obj;
    });
    items = items[0];

    items = items.items;

    return (<div className=" relative">
      <div className="absolute z-index-front left-3 h-100 flex align-center">
        <MdLocationOn className="font-20" />
      </div>
      <Dropdown
        className="h-20 flex align-center pad-left-div"
        placeholder={i18n.t(`Kërko qytetin/lagjen`)}
        fluid
        search
        selection
        icon={false}
        options={items}
        onSearchChange={this.updateSearchLocations}
        onChange={this.dropdownChanged}
        item
      />
    </div>
    );
  };

  updateSearchLocations = (e) => {
    var typed = new RegExp(e.target.value);
    var searchedLoc = [];
    var allLoc = this.state.locations;
    searchedLoc = allLoc.filter((locationObj) => {
      if (typed.test(locationObj.value)) return locationObj;
    });
    this.setState({
      typed: true,
      searchedLocations: searchedLoc,
    });
  };

  updateCarSearch = (e) => {
    this.setState({
      gatheredData: {
        ...this.state.gatheredData,
        'input-car-model': e.target.value
      }
    })
  }

  dropdownChanged = (e) => {
    var location = e.target.textContent.split(" - ");
    var city = "";
    var neighborhood = "";
    location = e.target.textContent.split(" - ");
    if (location.length == 1) {
      city = location[0];
      neighborhood = "";
    } else {
      city = location[1];
      neighborhood = location[0];
    }
    this.setState({
      gatheredData: {
        ...this.state.gatheredData,
        "input-location": city,
        "input-neighborhood": neighborhood,
      },
    });
  };

  getValueBoxes = (gastronomi) => {
    var temp;
    if (gastronomi)
      temp = this.state.InputGroupGastronomi.filter((input) => {
        if (input.type == "number") {
          return input;
        }
      });
    else
      temp = this.state.InputGroup.filter((input) => {
        if (input.type == "number") {
          return input;
        }
      });
    var inputDiv = "h-100 w-75  ";
    var inputDivSearch = "h-100 w-100 ";
    var i = 0;
    var anotherTemp = [];
    while (i < temp.length) {
      var post = temp[i];
      if (i != 2)
        var postTemp = {
          ...post,
          inputDiv: inputDiv,
        };
      else
        var postTemp = {
          ...post,
          inputDiv: inputDivSearch,
        };
      anotherTemp.push(postTemp);
      i++;
    }
    return (
      <div className="flex flex-space-between h-15">
        {anotherTemp.map((inp, index) => {
          return (
            <div key={index} className="flex w-100 border-form">
              {this.isIconBeforeInput(inp.label.text)
                ? this.getIcon(
                  inp.label.icon,
                  inp.label.class,
                  inp.label.iconClass
                )
                : ""}
              <CustomInput
                handleFocus={this.doNothing}
                handleInput={this.handleInputChange}
                data={inp}
                value={inp.inputName == "total-price" ? inp.value : undefined}

              />
            </div>
          );
        })}
      </div>
    );
  };
  isIconBeforeInput = (icon) => {
    if (icon != "icon") return false;
    return true;
  };

  getIcon = (icon, classN, iconClass) => {
    if (icon == "location")
      return (
        <div className={classN}>
          <MdLocationOn className={iconClass} />
        </div>
      );
    else if (icon == "home")
      return (
        <div className={classN}>
          <MdHome className={iconClass} />
        </div>
      );
    else if (icon == "dollar")
      return (
        <div className={classN}>
          <MdAttachMoney className={iconClass} />
        </div>
      );
    else if (icon == "Euro")
      return (
        <div className={classN}>
          <MdEuroSymbol className={iconClass} />
        </div>
      );
    else if (icon == 'person')
      return (
        <div className={classN}>
          <BsFillPersonFill className={iconClass} />
        </div>
      )
    else if (icon == "Mail")
      return (
        <div className={classN}>
          <MdMail className={iconClass} />
        </div>
      ); //Euro
  };
  handleInputChange = (el) => {
    var tempData;
    if (el.target.type == "checkbox") {
      tempData = {
        ...this.state.gatheredData,
        [el.target.name]: {
          val: el.target.value,
          checked: el.target.checked,
        },
      };
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          [el.target.name]: {
            val: el.target.value,
            checked: el.target.checked,
          },
        },
      });
    } else {
      tempData = {
        ...this.state.gatheredData,
        [el.target.name]: el.target.value,
      };
      this.setState({
        gatheredData: {
          ...this.state.gatheredData,
          [el.target.name]: el.target.value,
        },
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.searchingClicked(this.state.gatheredData);
  };

  getDropDownForProperty = () => {
    var accountOptions = [
      {
        key: "Banesë",
        text: "Banesë",
        value: "Banese",
      },
      {
        key: "Shtëpi",
        text: "Shtëpi",
        value: "Shtepi",
      },
      {
        key: "Zyrë",
        text: "Zyrë",
        value: "Zyre",
      },
      {
        key: "Tokë",
        text: "Tokë",
        value: "Toke",
      },
      {
        key: "Villë",
        text: "Villë",
        value: "Ville",
      },
      {
        key: "Të gjitha",
        text: "Të gjitha",
        value: "Te gjitha",
      },
    ];

    var cls =
      "flex align-center  account-dropdown w-100 bg-white  h-20 no-border light-gray radius-top-right-4 radius-bottom-right-4 ";
    if (this.state.searchTipiPrones != "Prona") cls += " black";

    return (
      <div className=" flex align-center form-control mt-5-px mt-2-vh ">
        <div className="bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray">
          <MdHome color="black" className="font-20" />
        </div>
        <Dropdown text={i18n.t(this.state.searchTipiPrones)} icon={false} className={cls}>
          <Dropdown.Menu className="w-100">
            {accountOptions.map((option) => (
              <Dropdown.Item
                key={option.text}
                value={option.value}
                {...option}
                onClick={(e) => this.selectedItem(option.key, e, option.value)}
              >
                {i18n.t(option.value)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  getDropDownForCarModel = () => {
    var items = CarList()

    return (<div className=" relative mt-5-px mt-2-vh">
      <div className="absolute z-index-front left-3 h-100 flex align-center">
        <AiFillCar className="font-20" />
      </div>
      <Dropdown
        className="h-20 flex align-center pad-left-div"
        placeholder={i18n.t("Kërko veturën")}
        fluid
        search
        selection
        icon={false}
        options={items}
        onSearchChange={this.updateCarSearch}
        onChange={this.dropdownChanged}
        item
      />
    </div>
    )
  }

  getDropDownForPropertyGastronomi = () => {
    var accountOptions = [
      {
        key: "Party",
        text: "Party",
        value: "Party",
      },
      {
        key: "Ushqim",
        text: "Ushqim",
        value: "Ushqim",
      },
      {
        key: "Kafe",
        text: "Kafe",
        value: "Kafe",
      },
      {
        key: "Dasma",
        text: "Dasma",
        value: "Dasma",
      },
      {
        key: "Të gjitha",
        text: "Të gjitha",
        value: "Te gjitha",
      },
    ];

    var cls =
      "flex align-center  account-dropdown w-100 bg-white  h-20 no-border light-gray radius-top-right-4 radius-bottom-right-4 ";

    if (this.state.searchTipiPrones != "Prona") cls += " black";

    return (
      <div className=" flex align-center form-control mt-5-px mt-2-vh ">
        <div className="bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray">
          <MdHome color="black" className="font-20" />
        </div>
        <Dropdown text={i18n.t(this.state.searchTipiPrones)} icon={false} className={cls}>
          <Dropdown.Menu className="w-100">
            {accountOptions.map((option) => (
              <Dropdown.Item
                key={option.text}
                value={option.value}
                {...option}
                onClick={(e) => this.selectedItem(option.key, e, option.value)}
              >
                {i18n.t(option.value)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  selectedItem = (key, e, val) => {
    var value = "";
    if (key != "Të gjitha") value = val;
    this.setState({
      searchTipiPrones: key,
      gatheredData: {
        ...this.state.gatheredData,
        "input-property-type": value,
      },
    });
  };

  getNeighborhoodInput = () => {
    var obj = {
      label: {
        text: "", //if empty the label wont show (it is defined in custom form)
        class:
          "bg-white h-100 flex align-center radius-top-left-4 radius-bottom-left-4 light-gray",
        icon: "home",
        iconClass: "font-20",
      },
      inputClass:
        "pl-5 radius-top-left-4 radius-bottom-left-4 radius-top-right-4 radius-bottom-right-4  inherit-height   no-border w-100",
      type: "text",
      placeholder: "Lagjja",
      disabled: false,
      inputName: "input-neighborhood",
      formGroup: "w-10 flex flex-center margin-bottom-0 ",
      inputDiv: "h-100 w-100 mt-3",
    };

    return (
      <div className="flex w-100 max-35">
        {this.isIconBeforeInput(obj.label.text)
          ? this.getIcon(obj.label.icon, obj.label.class, obj.label.iconClass)
          : ""}
        <CustomInput
          handleFocus={this.doNothing}
          handleInput={this.handleInputChange}
          data={obj}
          value={obj.inputName == "total-price" ? obj.value : undefined}
          euroReq
        />
      </div>
    );
  };
  notificationSystem = React.createRef();

  notImplementedYet = () => {
    var notification = this.notificationSystem.current;
    notification.addNotification({
      message: i18n.t("Se shpejti!"),
      level: "info",
      position: "tc",
      autoDismiss: "5",
      dismissible: "both",
    });
  };



  getSearchButton = () => {
    const OPTIONS = {
      'blue': 'blue-background',
      'orange': 'orange-background',
      'purple': 'purple-background',
    }
    var temp = this.state.InputGroup.filter((input) => {
      if (input.type == "submit") {
        input.inputClass = input.inputClass.replace('blue-background', '')
        input.inputClass = input.inputClass.replace('orange-background', '')
        input.inputClass = input.inputClass.replace('purple-background', '')
        input.inputClass = input.inputClass + ' ' + (OPTIONS[this.state.mainColor])


        return input;
      }
    });
    var inputDiv = "h-100 w-100  ";
    var inputDivSearch = "h-100 w-100 ";
    var i = 0;
    var anotherTemp = [];
    while (i < temp.length) {
      var post = temp[i];
      if (i != 2)
        var postTemp = {
          ...post,
          inputDiv: inputDiv,
        };
      else
        var postTemp = {
          ...post,
          inputDiv: inputDivSearch,
        };
      anotherTemp.push(postTemp);
      i++;
    }
    return (
      <div className="flex flex-space-between h-15 mt-3">
        {anotherTemp.map((inp, index) => {
          return (
            <div className="flex w-100 border-form">
              {this.isIconBeforeInput(inp.label.text)
                ? this.getIcon(
                  inp.label.icon,
                  inp.label.class,
                  inp.label.iconClass
                )
                : ""}
              <CustomInput
                handleFocus={this.doNothing}
                handleInput={this.handleInputChange}
                data={inp}
                value={inp.inputName == "total-price" ? i18n.t(inp.value) : undefined}
                euroReq
              />
            </div>
          );
        })}
      </div>
    );
  }
  ColoredLine = () => (
    <hr
      style={{
        color: 'gray',
        backgroundColor: 'gray',
        height: 5
      }}
    />
  );

  typeChanged = (type) => {
    var options = {
      'patundshmeri': 'blue',
      'gastronomi': 'purple',
      'vetura': 'orange'
    }
    var gastro = false;
    var cars = false;
    if (type === 'gastronomi') gastro = true
    if (type === 'vetura') cars = true
    this.setState({
      typeSearch: type,
      mainColor: options[type],
      gastronomi: gastro,
      vetura: cars,
      gatheredData: {
        ...this.state.gatheredData,
        "input-property-type": type,
      },
    })

    this.props.changeBackgroundPhoto(type)
  }

  getSearchClass = (type) => {
    var cls = 'main-button search-btn';
    var stateType = this.state.typeSearch;
    if ((stateType === "" && type == 'patundshmeri') || stateType == type) {
      cls += " active-button-add"
    }

    return cls
  }

  getSearchWrapperClass = () => {
    var cls = 'flex ';
    if (this.state.typeSearch == "" || this.state.typeSearch == 'patundshmeri') {
      cls += " blue-underline"
    }
    else if (this.state.typeSearch == 'gastronomi') {
      cls += " purple-underline"
    }
    else if (this.state.typeSearch == 'vetura') {
      cls += " orange-underline"
    }
    return cls
  }
  render() {
    const responsive = this.state.deviceWidth < 769 ? true : false;
    const formDiv = responsive
      ? "responsive-design height-20-vh w-90 left-5 bg-white black"
      : "search-container bg-white flex-grow-1 p-10 black";
    const locations = this.state.locations;
    const gastronomi = this.state.gastronomi;
    const vetura = this.state.vetura;
    const inputGroup = gastronomi ? this.state.InputGroupGastronomi : vetura ? this.state.InputGroupVetura : this.state.InputGroup
    return (
      <div className={formDiv}>
        <NotificationSystem ref={this.notificationSystem} />
        <div className={this.getSearchWrapperClass()}>
          <button className={this.getSearchClass('patundshmeri')} onClick={() => this.typeChanged('patundshmeri')}>
            {i18n.t('real estate')}
          </button>
          <button className={this.getSearchClass('gastronomi')} onClick={() => this.typeChanged('gastronomi')}>
            {i18n.t('gastronomi')}
          </button>
          <button className={this.getSearchClass('vetura')} onClick={() => this.typeChanged('vetura')}>
            {i18n.t('cars')}
          </button>
        </div>
        {!responsive ? (
          locations ? (
            <CustomForm
              handleSubmit={this.searchingClicked}
              formclass="p-3 align-center h-90 wrap"
              data={inputGroup}
              mainColor={this.state.mainColor}
            />

          ) : (
            ""
          )
        ) : (

          <Form
            onSubmit={this.handleSubmit}
            className="search-responsive w-80 h-90 top-5 relative left-10 flex vertical-flex"
          >
            {!gastronomi && !vetura && this.getCheckboxes(gastronomi)}
            {vetura && this.getCheckboxesForCars(vetura)}
            {!gastronomi && <div className="divider-line"></div>}
            {locations != null ? this.getAdressInput() : ""}
            {gastronomi && this.getDropDownForPropertyGastronomi()}
            {vetura && this.getDropDownForCarModel()}
            {!gastronomi && !vetura && this.getDropDownForProperty()}
            <div className="divider-line"></div>
            <FormLabel style={{ textAlign: 'left' }}>{gastronomi ? i18n.t('Personat') : i18n.t('Çmimi')}</FormLabel>
            {this.getValueBoxes(gastronomi)}
            {this.getSearchButton()}
          </Form>

        )}
      </div>
    );
  }
}


export default withRouter(SearchBar);
