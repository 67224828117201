import CardPost from '../components/Card-Post/CardPost';
import React, { Component } from "react";
import PostService from "../services/PostService";
import { tokenExists, setJWT, getUserId } from "../services/TokenService";
import NotificationSystem from "react-notification-system";
import withRouter from "react-router-dom/withRouter";
import SmallImage from '../components/SmallImage/SmallImage';
import { ClipLoader } from "react-spinners";
import { Redirect } from "react-router";
import i18n from "../i18configuration/i18";
import GoogleMapCustom from "../components/Map/GoogleMapCustom";
import GoogleMapService from "../services/GoogleMapService";
import { FormLabel, FormControl, Button } from "react-bootstrap";
import CustomForm from 'components/CustomForm/CustomForm';
import { CarList, CitiesList, Colors, PetrolType } from "../helpers/temp.js";
import { TransmisionTypes } from "helpers/types";
import { PostTypes } from 'helpers/postTypes';

const Cities = CitiesList();
const Cars = CarList();
const PetrolTypes= PetrolType()
const ColorsTypes=Colors()
const Transmision=TransmisionTypes()
class UpdatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMap: true,
      propertyType: "",
      loading: true,
      processing: false,
      photoDeleted: false,
      characteristicType: 'patundshmeri',
      initialIndex: -1,
      initial: true,
      postTypeId: 0,
      updateData: true,
      index: 0,
      previewPost: {
        initial: true,
        primaryPhoto: "",
        rent: false,
        selling: true,
        postDescription: [
          {
            icon: "",
            info: "",
          },
        ],
        description: "",
        title: "",
        photos: [
          {
            blobString: "",
          },
        ],
      },
      previewPostStyle: {
        postClass:
          "w-60 light-box-shadow bg-white m-auto height-90-vh m-vertical-15-px",
        postDescriptionClass: "flex flex-space-between",
      },
      InputGroup: undefined,
    };
  }

  postService = new PostService();

  componentWillMount() {
    if (
      this.state.previewPost == undefined ||
      this.state.previewPost.post == undefined
    ) {
      if (
        this.props.match.params.postId == "" &&
        this.props.match.params.postId < 1
      )
        this.props.history.push("/user/dashboard");
      this.postService
        .getPostById(this.props.match.params.postId)
        .then((res) => {
          this.setState({
            previewPost: res.data.data,
            loading: false,
            propertyType: res.data.data.postType.description,
            showMap: res.data.data.showMap,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    }

    if (!tokenExists()) {
      this.props.history.push("/user/dashboard");
    }
  }
  notificationSystem = React.createRef();

  arangePhotos = (post) => {
    post.allPhotos = post.photos;
    this.setState({
      previewPost: post,
      newPreviewPost: undefined,
    });
  };

  componentDidUpdate() {
    if (!this.state.descriptionSet)
      this.createPostDescription(this.state.previewPost);
    if (!this.state.primrayPhotoSet)
      this.findAndSetPrimaryPhoto(this.state.previewPost);
    if (this.state.previewPost.active == true && !this.state.inputGroupSet) {
      this.setInputGroup();
      this.setState({ inputGroupSet: true });
    }
    if (this.state.photoDeleted) {
      this.arangePhotos(this.state.newPreviewPost);
      this.setState({
        photoDeleted: false,
      });
    }

    if (
      this.state.previewPost.active == true &&
      this.state.inputGroupSet &&
      this.state.currentPropertySet == undefined
    ) {
      this.currentPropertyType();
      this.setState({
        currentPropertySet: true,
      });
    }
    if (this.state.updateData) {
      this.setState({
        updateData: false,
      });
    }
    if (this.state.InputGroup && this.state.previewPost.brand) {
      let toHideElements = this.state.InputGroup?.filter(input => input.inputName === 'kilometers')[0]
      if (this.state.previewPost.brand.replace('undefined,', '').split(',').length > 1 && toHideElements.hidden == false) {
        let hidden = true
        let changeVisibility = {
          kilometers: hidden,
          transmision: hidden,
          nrSeats: hidden,
          nrDoors: hidden,
          petrolType: hidden,
          placeOfOrigin: hidden,
          color: hidden,
          manufactureYear: hidden,
          model: hidden
        };
        this.setPropertyHiddenValue(changeVisibility, {});
      }
      else if (this.state.previewPost.brand.replace('undefined,', '').split(',').length <= 1 && toHideElements.hidden == true) {
        let hidden = false
        let changeVisibility = {
          kilometers: hidden,
          transmision: hidden,
          nrSeats: hidden,
          nrDoors: hidden,
          petrolType: hidden,
          placeOfOrigin: hidden,
          color: hidden,
          manufactureYear: hidden,
          model: hidden
        };

        this.setPropertyHiddenValue(changeVisibility, {});
      }
    }

  }
  findAndSetPrimaryPhoto = (post) => {
    var primaryPhotoBlob = "";
    var primaryExt = "";
    var index = -1;
    if (
      post.photos != undefined &&
      post.photos != null &&
      post.photos.length > 0
    ) {
      for (var i = 0; i < post.photos.length; i++) {
        if (post.photos[i].primary) {
          primaryPhotoBlob = post.photos[i].blobString;
          primaryExt = post.photos[i].extension;
          index = i;
          break;
        }
      }
      if (index >= 0) {
        this.setState({
          initialIndex: index,
        });
      }
      this.setState({
        previewPost: {
          ...post,
          primaryPhoto: primaryPhotoBlob,
          primaryPhotoExtension: primaryExt,
        },
        primrayPhotoSet: true,
      });
    }
  };

  //when is loaded, what kind of data to be shown
  currentPropertyType = () => {
    var propertyType =
      this.state.previewPost.postType.description.toLowerCase();
    var propertyValue = 0;
    var kateHidden = true;
    var floorNum;
    var dhomaZyre; //change text
    var banjoHidden = false;
    var dhomaHidden = false;
    var hapesiraAri;
    var kateText;
    var fletaPosedueseHidden = false;
    var NumberOfPeopleAllowedHidden = true;
    var shitjeHidden = false;
    var QeraHidden = false;
    var cimereHidden = false;
    var amenitiesHidden = false;
    var cmimiText = 'Çmimi*'


    //gastronomi

    var kafeHidden = true;
    var dasmaHidden = true;
    var loungeHidden = true;
    var spaceHidden = false;
    var partyHidden = true;
    var muzikeHidden = true;
    var ushqimHidden = true;

    //vetura
    var brand = true;
    var model = true;
    var color = true;
    var petrolType = true;
    var manufactureYear = true;
    var kilometers = true;
    var transmision = true;
    var nrSeats = true;
    var nrDoors = true;
    var placeOfOrigin = true;
    var customsChecked = true;
    var dailyPriceHidden = true

    if (propertyType == "shtepi" || propertyType == "patundshmeri") {
      propertyValue = PostTypes.Shtepi;
      kateHidden = false;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "cimere") {
      propertyValue = PostTypes.Cimere;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "banese") {
      propertyValue = 1;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "zyre") {
      propertyValue = PostTypes.Zyre;
      kateHidden = true;
      dhomaZyre = true; //change text
      floorNum = false;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "toke") {
      propertyValue = 5;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Ari (m2)*";
    } else if (propertyType == "lokal") {
      propertyValue = PostTypes.Lokal;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = false;
      dhomaHidden = true;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
    } else if (propertyType == "ville") {
      propertyValue = 7;
      kateHidden = false;
      dhomaZyre = false;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      spaceHidden = true
    } else if (propertyType == "gastronomi") {
      propertyValue = PostTypes.Ahengje;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      shitjeHidden = true;
      QeraHidden = true;
      cimereHidden = true;
      dasmaHidden = false;
      loungeHidden = false;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = false;
      muzikeHidden = false;
      ushqimHidden = false;
      kafeHidden = false;
    } else if (propertyType == "vetura") {
      propertyValue =PostTypes.Vetura;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = true;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      fletaPosedueseHidden = true;
      shitjeHidden = false;
      QeraHidden = false;
      cimereHidden = true;
      dasmaHidden = true;
      loungeHidden = true;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = true;
      muzikeHidden = true;
      ushqimHidden = true;
      kafeHidden = true;
      brand = false;
      model = false;
      color = false;
      customsChecked = false;
      petrolType = false;
      manufactureYear = false;
      kilometers = false;
      transmision = false;
      nrSeats = false;
      nrDoors = false;
      placeOfOrigin = false;
      dailyPriceHidden = false;
      cmimiText = 'Çmimi mujor (opsional)'
    }
    var changeVisibility = {
      party: partyHidden,
      kafe: kafeHidden,
      muzike: muzikeHidden,
      ushqim: ushqimHidden,
      bathrooms: banjoHidden,
      space: spaceHidden,
      kate: kateHidden,
      floor: floorNum,
      rooms: dhomaHidden,
      fletePoseduese: fletaPosedueseHidden,
      numberOfPeopleAllowed: NumberOfPeopleAllowedHidden,
      qera: QeraHidden,
      selling: shitjeHidden,
      cimere: cimereHidden,
      dasma: dasmaHidden,
      lounge: loungeHidden,
      amenities: amenitiesHidden,
      brand,
      model,
      color,
      petrolType,
      manufactureYear,
      kilometers,
      transmision,
      nrSeats,
      nrDoors,
      placeOfOrigin,
      dailyPrice: dailyPriceHidden
    };
    var changeText = {
      rooms: dhomaZyre,
      space: hapesiraAri,
      kate: kateText,
      price: cmimiText
    };
    var type = propertyType || "patundshmeri"
    var temp = this.state.InputGroup.map(inp => {
      if (inp.type == 'tagcreator')
        inp.itemTypes = type
      return inp
    })
    this.setState({

      characteristicType: type,
      InputGroup: temp
    })

    this.setPropertyHiddenValue(changeVisibility, changeText);



    this.setState({
      postTypeId: propertyValue,
      propertyType: propertyType,
    });
  };

  propertyTypeClicked = (e) => {
    var propertyType = e.target.value;
    var propertyValue = 0;
    var kateHidden = true;
    var floorNum;
    var dhomaZyre; //change text
    var banjoHidden = false;
    var dhomaHidden = false;
    var hapesiraAri;
    var kateText;
    var fletaPosedueseHidden = false;
    var NumberOfPeopleAllowedHidden = true;
    var shitjeHidden = false;
    var QeraHidden = false;
    var cimereHidden = false;
    var amenitiesHidden = false;
    var cmimiText = 'Çmimi*'


    //gastronomi

    var kafeHidden = true;
    var dasmaHidden = true;
    var loungeHidden = true;
    var spaceHidden = false;
    var partyHidden = true;
    var muzikeHidden = true;
    var ushqimHidden = true;

    //vetura
    var brand = true;
    var model = true;
    var color = true;
    var petrolType = true;
    var manufactureYear = true;
    var kilometers = true;
    var transmision = true;
    var nrSeats = true;
    var nrDoors = true;
    var placeOfOrigin = true;
    var customsChecked = true;
    var dailyPriceHidden = true

    if (propertyType == "shtepi" || propertyType == "patundshmeri") {
      propertyValue = 2;
      kateHidden = false;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "cimere") {
      propertyValue = 3;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      kateText = "Numri i kateve te shtepise*";
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "banese") {
      propertyValue = 1;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Hapësira (m2)";
    } else if (propertyType == "zyre") {
      propertyValue = 4;
      kateHidden = true;
      dhomaZyre = true; //change text
      floorNum = false;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)";
      fletaPosedueseHidden = true;
    } else if (propertyType == "toke") {
      propertyValue = 5;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*";
      hapesiraAri = "Ari (m2)*";
    } else if (propertyType == "lokal") {
      propertyValue = 6;
      kateHidden = true;
      dhomaZyre = false;
      floorNum = false;
      dhomaHidden = true;
      dhomaZyre = "Njësi(dhoma)*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
    } else if (propertyType == "ville") {
      propertyValue = 7;
      kateHidden = false;
      dhomaZyre = false;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      spaceHidden = true
    } else if (propertyType == "gastronomi") {
      propertyValue = 8;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = false;
      floorNum = true;
      kateText = "Numri i kateve te villes*";
      banjoHidden = true;
      dhomaHidden = true;
      dhomaZyre = "Dhoma*";
      hapesiraAri = "Hapësira (m2)*";
      fletaPosedueseHidden = true;
      shitjeHidden = true;
      QeraHidden = true;
      cimereHidden = true;
      dasmaHidden = false;
      loungeHidden = false;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = false;
      muzikeHidden = false;
      ushqimHidden = false;
      kafeHidden = false;
    } else if (propertyType == "vetura") {
      propertyValue = PostTypes.Vetura;
      kateHidden = true;
      dhomaZyre = true;
      NumberOfPeopleAllowedHidden = true;
      floorNum = true;
      banjoHidden = true;
      dhomaHidden = true;
      fletaPosedueseHidden = true;
      shitjeHidden = false;
      QeraHidden = false;
      cimereHidden = true;
      dasmaHidden = true;
      loungeHidden = true;
      amenitiesHidden = false;
      spaceHidden = true;
      partyHidden = true;
      muzikeHidden = true;
      ushqimHidden = true;
      kafeHidden = true;
      brand = false;
      model = false;
      color = false;
      customsChecked = false;
      petrolType = false;
      manufactureYear = false;
      kilometers = false;
      transmision = false;
      nrSeats = false;
      nrDoors = false;
      placeOfOrigin = false;
      dailyPriceHidden = false;
      cmimiText = 'Çmimi mujor (opsional)'
    }
    var changeVisibility = {
      party: partyHidden,
      kafe: kafeHidden,
      muzike: muzikeHidden,
      ushqim: ushqimHidden,
      bathrooms: banjoHidden,
      space: spaceHidden,
      kate: kateHidden,
      floor: floorNum,
      rooms: dhomaHidden,
      fletePoseduese: fletaPosedueseHidden,
      numberOfPeopleAllowed: NumberOfPeopleAllowedHidden,
      qera: QeraHidden,
      selling: shitjeHidden,
      cimere: cimereHidden,
      dasma: dasmaHidden,
      lounge: loungeHidden,
      amenities: amenitiesHidden,
      brand,
      model,
      color,
      petrolType,
      manufactureYear,
      kilometers,
      transmision,
      nrSeats,
      nrDoors,
      placeOfOrigin,
      dailyPrice: dailyPriceHidden
    };
    var changeText = {
      rooms: dhomaZyre,
      space: hapesiraAri,
      kate: kateText,
      price: cmimiText
    };
    var type = propertyType || "patundshmeri"
    var temp = this.state.InputGroup.map(inp => {
      if (inp.type == 'tagcreator')
        inp.itemTypes = type
      return inp
    })
    this.setState({

      characteristicType: type,
      InputGroup: temp
    })
    this.setPropertyHiddenValue(changeVisibility, changeText);

    this.setState({
      postTypeId: propertyValue,
      propertyType: propertyType,
    });
  };
  setPropertyHiddenValue = (changeVisibility, changeText) => {
    var inputG = this.state.InputGroup;
    var keys = Object.keys(changeVisibility);
    var value = Object.values(changeVisibility);
    var temp;
    //change visibility
    for (var i = 0; i < keys.length; i++) {
      temp = inputG.filter((obj) => {
        if (keys.length > 0 && obj.inputName != keys[i]) return obj;
      });
      var objAt = 0;
      var tempObj = inputG.filter((obj, index) => {
        if (obj.inputName == keys[i]) {
          objAt = index;
          return obj;
        }
      });
      tempObj = tempObj[0];
      tempObj = {
        ...tempObj,
        hidden: value[i],
      };

      temp.splice(objAt, 0, tempObj);
      inputG = temp;
    }

    keys = Object.keys(changeText);
    value = Object.values(changeText);
    //change text
    for (var i = 0; i < keys.length; i++) {
      temp = inputG.filter((obj) => {
        if (obj.inputName != keys[i]) return obj;
      });
      var objAt = 0;
      var tempObj = inputG.filter((obj, index) => {
        if (obj.inputName == keys[i]) {
          objAt = index;
          return obj;
        }
      });
      tempObj = tempObj[0];
      tempObj = {
        ...tempObj,
        label: {
          ...tempObj.label,
          text: value[i],
        },
      };

      temp.splice(objAt, 0, tempObj);
      inputG = temp;
    }
    this.setState({
      InputGroup: temp,
    });
  };

  setInputGroup() {

    this.setState({
      InputGroup: [
        {
          label: {
            text: "Qera", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "rent",
          namingProperty: "qera",
          disabled: false,
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: false,
          checked: this.state.previewPost.rent,
        },
        {
          label: {
            text: "Shitje", //if empty the label wont show (it is defined in custom form)
            class: "m-0  text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "selling",
          disabled: false,
          namingProperty: "selling",
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: false,
          checked: this.state.previewPost.selling,
        },
        {
          label: {
            text: "Cimer/e", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "cimere",
          namingProperty: "cimere",
          disabled: false,
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: false,
          checked: this.state.previewPost.cimere,
        }, {
          label: {
            text: "Ushqim", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "ushqim",
          namingProperty: "ushqim",
          disabled: false,
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: true,
        }, {
          label: {
            text: "Kafe", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "kafe",
          namingProperty: "kafe",
          disabled: false,
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: true,
        }, {
          label: {
            text: "Muzikë (Party)", //if empty the label wont show (it is defined in custom form)
            class: "m-0 text-left ",
          },
          inputClass: "w-100 m-0-important checkbox-add ml-5-px ",
          type: "radio",
          placeholder: "Shkruaj titullin",
          value: "party",
          namingProperty: "party",
          disabled: false,
          inputName: "shitje-qera",
          inputDiv: "w-3 input-div-add-post input-radio-add",
          formGroup: "flex  m-vertical-10-px mr-20-px  relative align-center ",
          hidden: true,
        }, {
          label: {
            text: "Titulli i postimit*", //if empty the label wont show (it is defined in custom form)
            class: " ",
          },
          inputClass: "w-100 m-0-important text",
          type: "text",
          placeholder: "Titulli i postimit*",
          text: "",
          disabled: false,
          inputName: "title",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          value: this.state.previewPost.title,
        },
        {
          label: {
            text: "Qyteti*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          placeholder: "Qyteti/Rajoni*",
          disabled: false,
          inputName: "city",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          min: 1,
          items: Cities,
          chosen: this.state.previewPost["input-location"],
        },
        {
          label: {
            text: "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          placeholder: "Marka*",
          disabled: false,
          inputName: "brand",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          hidden: true,
          min: 1,
          ignoreLocation: true,
          multiple: true,
          items: Cars,
          chosen: this.state.previewPost["brand"],
        },
        {
          label: {
            text: "Hapësira (m2)*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Hapësira (m2)*",
          disabled: false,
          inputName: "space",
          formGroup: "flex vertical-flex m-vertical-5-px align-start  w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: false,
          value: this.state.previewPost.space,
        }, {
          label: {
            text: "Modeli*", //if empty the label wont show (it is defined in custom form)
            class: " ",
          },
          inputClass: "w-100 m-0-important text",
          type: "text",
          placeholder: "Modeli vetures*",
          text: "",
          disabled: false,
          hidden: true,
          inputName: "model",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          value: this.state.previewPost.model,
        }, {
          label: {
            text: "Transmisioni*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          placeholder: "Transmisioni*",
          disabled: false,
          inputName: "transmision",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          hidden: true,
          min: 1,
          ignoreLocation: true,
          items: Transmision,
          chosen: this.state.previewPost.transmision,
        }, {
          label: {
            text: "Ngjyra vetures*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          ignoreLocation: true,
          placeholder: "Ngjyra*",
          disabled: false,
          inputName: "color",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          hidden: true,
          items: ColorsTypes,
          chosen: this.state.previewPost.color,
        },
        {
          label: {
            text: "Karburanti*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          ignoreLocation: true,
          placeholder: "Lloji karburantit*",
          disabled: false,
          inputName: "petrolType",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          hidden: true,
          min: 1,
          items: PetrolTypes,
          chosen: this.state.previewPost.petrolType,
        }, {
          label: {
            text: "Viti prodhimit*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Viti prodhimit*",
          disabled: false,
          inputName: "manufactureYear",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
          value: this.state.previewPost.manufactureYear,
        }, {
          label: {
            text: "Kilometrazha*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Kilometrazha*",
          disabled: false,
          inputName: "kilometers",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
          value: this.state.previewPost.kilometers,
        }, {
          label: {
            text: "Numri dyerve*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Numri dyerve*",
          disabled: false,
          inputName: "nrDoors",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
          value: this.state.previewPost.nrDoors,
        }, {
          label: {
            text: "Numri uleseve*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Numri uleseve*",
          disabled: false,
          inputName: "nrSeats",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
          value: this.state.previewPost.nrSeats,
        }, {
          label: {
            text: "Vendi origjines*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "text",
          placeholder: "Vendi origjines*",
          disabled: false,
          inputName: "placeOfOrigin",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
          value: this.state.previewPost.placeOfOrigin,
        },
        {
          label: {
            text: "Banjo*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Banjo*",
          disabled: false,
          inputName: "bathrooms",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: false,
          value: this.state.previewPost.bathrooms,
        },
        {
          label: {
            text: "Numri kateve te shtepise*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Numri kateve te shtepise*",
          disabled: false,
          hidden: true,
          inputName: "kate",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          value: this.state.previewPost.kate,
        },
        {
          label: {
            text: "Dhoma (Vendos 0 nëse dëshironi të figuroj 'Garsonjerkë')*", //if empty the label wont show (it is defined in custom form)
            class: "",
            zyreText: "Numri zyreve (numrin hapësirave të brendshme)*",
            normalText: "Dhoma*",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Dhoma*",
          disabled: false,
          inputName: "rooms",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 0,
          value: this.state.previewPost.rooms,
        },
        {
          label: {
            text: "Linku i profilit ne facebook:(opsional)",
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "text",
          placeholder: "Linku i profilit ne facebook:(opsional)",
          disabled: false,
          inputName: "facebookLink",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          hidden: true,
          value: this.state.previewPost.facebookLink,
        },
        {
          label: {
            text: "Numri i katit*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Numri i katit (vendos 0 për përdhesë)*",
          disabled: false,
          inputName: "floor",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: -10,
          hidden: true,
          value: this.state.previewPost.floor,
        }, {
          label: {
            text: "Numri personave te lejuar*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Numri personave te lejuar*",
          disabled: false,
          inputName: "numberOfPeopleAllowed",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 1,
          hidden: true,
        },
        {
          label: {
            text: "Fletë poseduese*:",
            class:
              "m-0 height-8-vh text-left align-self-start flex w-35 align-center",
          },
          inputClass: "w-100 m-0-important ",
          type: "checkbox",
          placeholder: "",
          disabled: false,
          inputName: "fletePoseduese",
          value: undefined,
          inputDiv: "w-3 flex align-center height-8-vh min-width-18-px ml-5-px",
          formGroup: "flex  align-self-start align-center w-100",
          checked: this.state.previewPost.fletePoseduese,
        }, {
          label: {
            text: "I doganuar*:",
            class:
              "m-0 height-8-vh text-left align-self-start flex w-35 align-center",
          },
          inputClass: "w-100 m-0-important ",
          type: "checkbox",
          placeholder: "",
          disabled: false,
          inputName: "customsChecked",
          value: undefined,
          inputDiv: "w-3 flex align-center height-8-vh min-width-18-px ml-5-px",
          formGroup: "flex  align-self-start align-center w-100",
          hidden: true,
          checked: this.state.previewPost.customsChecked,
        },
        {
          label: {
            text: "Përshkrimi", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important form-control ",
          type: "textarea",
          placeholder: "Përshkrimi",
          disabled: false,
          inputName: "description",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start w-100 textarea-group",
          inputDiv: "height-5-vh w-100",
          value: this.state.previewPost.description,
        },
        ,
        {
          label: {
            text: "Karakteristikat:", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "tagcreator",
          placeholder:
            "Psh wifi ( për të shtuar më shumë kliko butonin shto më shumë )",
          disabled: false,
          inputName: "amenities",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: " w-100",
          hidden: false,
          itemTypes: 'patundshmeri',
          tags: this.state.previewPost.tagsArray,//potential error
        },
        {
          label: {
            text: "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Çmimi* ",
          disabled: false,
          inputName: "price",
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 0,
          value: this.state.previewPost.price,
        }, {
          label: {
            text: "Çmimi ditor*", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "number",
          placeholder: "Çmimi ditor* ",
          disabled: false,
          inputName: "dailyPrice",
          hidden: true,
          formGroup: "flex vertical-flex m-vertical-5-px align-start w-100",
          inputDiv: "height-5-vh w-100",
          min: 0,
          value: this.state.previewPost.dailyPrice,
        },
        {
          label: {
            text: "Currency", //if empty the label wont show (it is defined in custom form)
            class: "",
          },
          inputClass: "w-100 m-0-important ",
          type: "dropdown",
          dropdownFancySemantic: true,
          placeholder: "Currency*",
          disabled: false,
          inputName: "currency",
          formGroup:
            "flex vertical-flex m-vertical-5-px align-start  w-100 arber",
          inputDiv: "height-5-vh w-100",
          hidden: false,
          min: 1,
          ignoreLocation: true,
          multiple: false,
          chosen: this.state.previewPost.currency,
          items: [{
            key: "Euro",
            value: "Euro",
            text: "Euro",
          }, {
            key: "USD",
            value: "USD",
            text: "USD",
          },],
        },
        {
          label: {
            text: "Zgjedh deri ne 15 foto: ", //if empty the label wont show (it is defined in custom form)
            class: "w-30",
          },
          inputClass: "w-auto m-0-important file-input  h-auto",
          type: "file",
          placeholder: "Shkruaj përshkrimin ketu...",
          disabled: false,
          inputName: "description",
          formGroup: "flex  m-vertical-15-px align-start w-100 align-center",
          inputDiv: "h-auto w-100",
          multiple: true,
          accept: "image/*",
        },
      ]
    });
  }

  buttonData = {
    label: {
      text: "", //if empty the label wont show (it is defined in custom form)
      class: "",
    },
    inputClass: "w-100 m-0-important white bg-blue",
    type: "submit",
    placeholder: "",
    disabled: false,
    inputName: "input-add",
    value: "Shto postimin",
    inputDiv: "h-100 w-20 input-submit",
    formGroup: "w-100 m-vertical-5-px flex flex-center w-100",
  };

  trimData = (verifyData) => {
    verifyData.title = verifyData.title.trim();
    if (
      verifyData.description != undefined &&
      verifyData.description.trim() != ""
    ) {
      verifyData.description = verifyData.description.trim();
    }
    return verifyData;
  };
  postService = new PostService();

  googleMapServivce = new GoogleMapService();
  updatePost = async () => {
    if (this.state.processing) return;
    this.setState({
      processing: true,
    });
    var data = this.state.previewPost;
    // if(!data.rent && !data.cimere && !data.selling){
    //     data.rent
    // }
    var notification = this.notificationSystem.current;
    var obj = getUserId();
    if (this.state.propertyType != "" && this.state.postTypeId != 0) {
      data = {
        ...data,
        postType: {
          postTypeId: this.state.postTypeId,
          description: this.state.propertyType,
        },
        showMap: this.state.showMap,
      };
    }
    data = {
      ...data,
      userId: obj,
    };
    var verification = this.verifyData(data);
    data = this.trimData(data);
    if (!verification) {
      this.setState({
        processing: false,
      });
      return;
    }

    await this.googleMapServivce
      .getAddress(data.latitude, data.longitude)
      .then((res) => {
        var objs = [];
        if (
          res != undefined &&
          res.data != undefined &&
          res.data.results.length > 0
        ) {
          objs = res.data.results.filter((obj) => {
            if (obj.types[0] == "route") return obj;
          });

          var neigh = "";
          if (objs[0].types != undefined) {
            if (objs[0].formatted_address.includes(","))
              neigh = objs[0].formatted_address.split(",")[0];
            else neigh = objs[0].formatted_address;
          }
          data = {
            ...data,
            neighborhood: neigh,
          };
        }

        this.setState({
          ...this.state.previewPost,
          neighborhood: neigh,
        });
      })
      .catch((err) => {
        //console.log(err)
      })
      .finally(() => {
        this.postService
          .update(data)
          .then((res) => {
            setJWT(res.data.token);
            notification.addNotification({
              message: i18n.t("Posti u perditesuar me sukses!"),
              level: "success",
              position: "tc",
              autoDismiss: "5",
              dismissible: "both",
            });

            var type = data.cimere
              ? "input-cimere=true"
              : data.rent
                ? "input-rent=true"
                : "input-buy=true";
            setTimeout(() => {
              this.props.history.push("/user/post?" + type + "&page=1");
            }, 3000);

            // setTimeout(this.sendToMyPosts, 3000);
          })
          .catch((err) => {
            notification.addNotification({
              message:
                i18n.t("Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perderi."),
              level: "warning",
              position: "tc",
              autoDismiss: "5",
              dismissible: "both",
            });
          })
          .finally(() => {
            this.setState({
              processing: false,
            });
          });
      });

  };

  sendToMyPosts() {
    <Redirect to="/user/post-list" />;
    // this.props.history.push("/user/post-list");
  }
  makeSmallPhotoActive = (index) => {
    var containers = document.getElementsByClassName("small-image-container");

    for (var i = 0; i < containers.length; i++) {
      if (containers[i].classList.contains("active-image"))
        containers[i].classList.remove("active-image");
    }
    containers[index].classList.add("active-image");
  };
  verifyData = (verifyData) => {
    var notification = this.notificationSystem.current;
    var message = "";
    var type = verifyData.rent | verifyData.selling | verifyData.cimere;
    var priceRegex = /^\d+.{0,1}\d{0,2}$/;
    var regex = /^\d+$/;
    var spaceRegex = /^\d+(\.\d{1,3}){0,1}$/;
    if (verifyData.postType.postTypeId != PostTypes.Ahengje && !type) {
      message = "Ju lutem të caktoni tipin e postit.";
    }
    if (!verifyData.postType.postTypeId) {
      message = "Ju lutem të caktoni llojin e postit.";
    } else if (!verifyData.title) {
      message = "Ju lutem të caktoni titullin e postit.";
    } else if (verifyData.title.length > 250) {
      message =
        "Titulli mund të ketë maksimalisht 250 karaktere. Ju keni shënuar " +
        verifyData.title.length +
        " karaktere";
    } else if ((![PostTypes.Ville , PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !verifyData.space)) {
      message = "Ju lutem të caktoni hapësirën.";
    } else if (![5, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && (!verifyData.bathrooms)) {
      message = "Ju lutem të caktoni numrin e banjove.";
    } else if (![5, 6, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && (!verifyData.rooms)) {
      message = "Ju lutem të caktoni numrin e dhomave.";
    } else if (verifyData.postType.postTypeId ==PostTypes.Vetura && verifyData.selling && !verifyData.price) {
      message = "Ju lutem të caktoni çmimin.";
    } else if (!verifyData["input-location"]) {
      message = "Ju lutem të caktoni qytetin/rajonin ku ndodhet.";
    } else if (
      !verifyData.photos ||
      verifyData.photos.length == 0
    ) {
      message = "Ju lutem të caktoni fotot për postin";
    } else if (!verifyData.primaryPhoto) {
      message =
        "Ju lutem të caktoni se cilën foto dëshironi ta keni të vendosur si foto kryesore";
    } else if (!verifyData["input-location"]) {
      message = "Ju lutem të caktoni qytetin/rajonin.";
    } else if ([PostTypes.Shtepi, PostTypes.Ville].includes(verifyData.postType.postTypeId) && !verifyData.kate) {
      message = "Ju lutem të caktoni numrin e kateve.";
    } else if ([PostTypes.Zyre, PostTypes.Lokal].includes(verifyData.postType.postTypeId) && !verifyData.floor) {
      message = "Ju lutem të caktoni katin ne te cilin ndodhet.";
    } else if ([PostTypes.Ville].includes(verifyData.postType.postTypeId) &&
      (!verifyData.numberOfPeopleAllowed ||
        verifyData.numberOfPeopleAllowed < 1)
    ) {
      message = "Ju lutem të caktoni numrin e personave të lejuar.";
    } else if (
      ![PostTypes.Ville, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !spaceRegex.test(verifyData.space)
    ) {
      message =
        "Nuk lejohet të shënohet shkronjë ne hapësirën e numrave për metër katror!";
    } else if (
      ![PostTypes.Toke, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !regex.test(verifyData.bathrooms)
    ) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e banjove!";
    } else if (![PostTypes.Toke, PostTypes.Lokal, PostTypes.Ahengje, PostTypes.Vetura].includes(verifyData.postType.postTypeId) && !regex.test(verifyData.rooms)) {
      message =
        "Nuk lejohet të shënohet shkronjë në hapësirën për numrin e dhomave!";
    } else if (verifyData.postType.postTypeId === PostTypes.Vetura && verifyData.selling && !priceRegex.test(verifyData.price)) {
      message =
        "Çmimi duhet të jetë i formatit numër apo numër me dy vlera pas presjes dhjetore!";
    } else if (verifyData.postType.postTypeId === PostTypes.Vetura && verifyData.rent && !priceRegex.test(verifyData.dailyPrice)) {
      message =
        "Çmimi duhet të jetë i formatit numër apo numër me dy vlera pas presjes dhjetore!";
    }

    if (message != "") {
      notification.addNotification({
        message: message,
        level: "warning",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return false;
    }
    return true;
  };

  createPostDescription = (previewPost) => {
    var postTemp = previewPost;
    var postDescription = [];
    postTemp = {
      ...postTemp,
      postDescription: postDescription,
      primaryPhoto: this.state.previewPost.primaryPhoto,
      primaryPhotoExtension: this.state.previewPost.primaryPhotoExtension,
    };
    if (postTemp) {
      if (postTemp.rooms && postTemp.rooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "bedroom",
            info: postTemp.rooms + " rooms",
          },
        ];

      if (postTemp.bathrooms && postTemp.bathrooms > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "shower",
            info: postTemp.bathrooms + " Baths",
          },
        ];

      if (postTemp.space && postTemp.space > 0)
        postTemp.postDescription = [
          ...postTemp.postDescription,
          {
            icon: "space",
            info: postTemp.space + postTemp.unit,
          },
        ];

      this.setState({
        previewPost: postTemp,
        needDescCreated: false,
        descriptionSet: true,
      });
    }
  };

  previewData = (data) => {
    this.createPostDescription(data);
  };

  photoLoad = (data, ended, double) => {
    if (ended) {

      this.setState({
        loadingPhoto: false,
      });
    }
    var notification = this.notificationSystem.current;
    if (double) {
      notification.addNotification({
        message:
          i18n.t("Nje nga imazhet e selektuara ka qene imazh i dublifikuar, eshte marre vetem nje here si foto"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
    }
    if (data.length > 15) {
      notification.addNotification({
        message:
          i18n.t("Keni zgjdhur me shume se numri i lejuar, maksimumi eshte 15 foto"),
        level: "error",
        position: "tc",
        autoDismiss: "5",
        dismissible: "both",
      });
      return;
    }
    var obj = this.state.previewPost;
    obj = {
      ...obj,
      photos: data,
      allPhotos: data,
    };

    this.setState({
      previewPost: {
        ...this.state.previewPost,
        photos: data,
        allPhotos: data,
      },
    });

    this.findAndSetPrimaryPhoto(obj);
  };

  getClass = (cls) => {
    var classN =
      "bg-blue white no-border font-18 add-listing-button radio-size ";
    if (cls == this.state.propertyType) classN += " active-button";
    return classN;
  };

  changeImage = (e, receivedIndex, image) => {

    e.preventDefault();
    this.setState({
      index: receivedIndex,
      previewPost: {
        ...this.state.previewPost,
        primaryPhoto: image.blobString,
        primaryPhotoExtension: image.extension,
      },
    });

    this.makeSmallPhotoActive(receivedIndex);
  };
  handleInputChange = (el) => {
    var tempData;
    if (el.target.type === "checkbox") {
      if (el.target.name === "agency") {
        tempData = {

          ...this.state.previewPost,
          isAgency: el.target.checked,
        };

        this.setState({

          previewPost: {
            ...this.state.previewPost,
            isAgency: el.target.checked,
          },
        });
      } else if (el.target.name === "fletePoseduese") {
        tempData = {

          ...this.state.previewPost,
          fletePoseduese: el.target.checked,
        };
        this.setState({

          previewPost: {
            ...this.state.previewPost,
            fletePoseduese: el.target.checked,
          },
        });
      } else {
        tempData = {

          ...this.state.previewPost,
          [el.target.name]: {
            val: el.target.value,
            checked: el.target.checked,
          },
        };
        this.setState({

          previewPost: {
            ...this.state.previewPost,
            [el.target.name]: {
              val: el.target.value,
              checked: el.target.checked,
            },
          },
        });
      }
    } else if (el.target.type === "radio") {
      var type = el.target.value;
      var rent = type === "rent" ? true : false;
      var selling = type === "selling" ? true : false;
      var cimere = type === "cimere" ? true : false;
      tempData = {

        ...this.state.previewPost,
        selling: selling,
        rent: rent,
        cimere: cimere,
      };
      this.setState({

        previewPost: {
          ...this.state.previewPost,
          selling: selling,
          rent: rent,
          cimere: cimere,
        },
      });
    } else if (el.target.type === "file") {
      tempData = {

        ...this.state.previewPost,

      };
      this.onImageSelected(el);
    } else {
      tempData = {

        ...this.state.previewPost,
        [el.target.name]: el.target.value,
      };

      this.setState({

        previewPost: {

          ...this.state.previewPost,
          [el.target.name]: el.target.value,
        },
      });
    }
  };

  onImageSelected = (event) => {
    if (event.target.files.length == 0) return;
    var filesList = this.createArray(event.target.files);

    this.readFiles(filesList);
  };
  createArray = (files) => {
    return Object.values(files);
  };

  readFiles = (filesList, filesConverted = []) => {
    if (filesList.length > 0) {
      // if we still have files left
      var reader = new FileReader();

      var file = filesList.shift(); // remove first from queue and store in file
      reader.readAsDataURL(file);

      reader.onloadend = async () => {
        filesConverted.push(reader.result);
        this.readFiles(filesList, filesConverted);
      };
    } else {
      var temp = [];
      var anotherTemp = {};
      for (var file of filesConverted) {
        anotherTemp = {
          blobString: file,
        };
        temp.push(anotherTemp);
      }
      this.setState({

        previewPost: {
          ...this.state.previewPost,
          photos: temp,
        },
      });
    }
  };
  updateFields = (e) => {
    this.handleInputChange(e);

    var tempinputs = this.state.InputGroup;
    var tempList = tempinputs.filter((inp) => {
      if (
        (inp.type === "checkbox" || inp.type === "radio") &&
        inp.inputName === e.target.name
      ) {
      } else if (inp.inputName === e.target.name) {
      } else return inp;
    });

    var index = 0;
    var tempObj = tempinputs.filter((inp, i) => {
      if (
        (inp.type === "checkbox" || inp.type === "radio") &&
        inp.inputName === e.target.name
      ) {
        index = i;
        return inp;
      } else if (inp.inputName === e.target.name) {
        index = i;
        return inp;
      } else {
      }
    });

    if (tempObj[0].type === "checkbox" || tempObj[0].type === "radio") {
      var ii = 0;
      while (ii < tempObj.length) {
        if (tempObj[ii].value == e.target.value) {
          tempObj[ii] = {
            ...tempObj[ii],
            checked: e.target.checked,
          };
        } else {
          tempObj[ii] = {
            ...tempObj[ii],
            checked: !e.target.checked,
          };
        }
        ii++;
      }
    } else if (tempObj.type !== "file") {
      tempObj = tempObj[0];
      tempObj = {
        ...tempObj,
        value: e.target.value,
      };
    }
    if (e.target.type === "checkbox" || e.target.type === "radio") {
      var ii = 0;
      index = index - 2;
      while (ii < tempObj.length) {
        tempList.splice(index++, 0, tempObj[ii]);
        ii++;
      }
    } else {
      tempList.splice(index, 0, tempObj);
    }
  };

  coordinatesSet = (obj) => {

    this.setState({
      previewPost: {
        ...this.state.previewPost,
        latitude: obj.lat,
        longitude: obj.lng,
      },
    });
  };

  deleteImage = (imageBlob) => {
    this.setState({
      photoDeleted: true,
    });
    if (imageBlob.includes("data:image")) imageBlob = imageBlob.split(",")[1];
    var photosArray = this.state.previewPost.photos.filter((obj) => {
      if (obj.blobString != imageBlob) return obj;
    });
    var tempPreview = this.state.previewPost;
    if (photosArray.length == 0) {
      photosArray.push({
        blobString: "",
      });
      document.getElementsByClassName("file-input")[0].value = "";
    }

    tempPreview = {
      ...tempPreview,
      photos: photosArray,
    };
    this.setState({
      newPreviewPost: tempPreview,
    });
  };
  loadingPhoto = (type) => {
    this.setState({
      loadingPhoto: true,
    });
  };
  getClassNameT = (num) => {
    var cls = "main-button";
    if ((![PostTypes.Ahengje, PostTypes.Vetura].includes(this.state.postTypeId) && num == -1) || this.state.postTypeId == num) cls += " active-button-add";

    return cls;
  };
  initialSetFalse = () => {
    this.setState({
      initial: false,
    });
  };
  facebookLinkVisibilityChanged = (inputName) => {
    var fletePosedueseHidden = this.state.postTypeId === PostTypes.Vetura || ["rent", "cimere", "dasma", "ushqim", 'kafe', 'muzike', 'party'].includes(inputName);
    var facebookLinkHidden = this.state.postTypeId === PostTypes.Vetura || ["rent", 'selling', "dasma", "ushqim", 'kafe', 'muzike', 'party'].includes(inputName);
    var customsCheckedHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'selling')
    var nrSeatsHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'rent')
    var nrDoorsHidden = this.state.postTypeId !== PostTypes.Vetura || (this.state.postTypeId === PostTypes.Vetura && inputName !== 'rent')
    var changeVisibility = {
      facebookLink: facebookLinkHidden,
      fletePoseduese: fletePosedueseHidden,
      nrSeats: nrSeatsHidden,
      nrDoors: nrDoorsHidden,
      customsChecked: customsCheckedHidden
    };
    var changeText = {};
    this.setPropertyHiddenValue(changeVisibility, changeText);
  };
  hideMapChanged = (e) => {
    this.setState({
      showMap: !e.target.checked,
    });
  };

  doNothing = () => { };

  notImplementedYet = () => {
    var notification = this.notificationSystem.current;
    notification.addNotification({
      message: i18n.t("Se shpejti!"),
      level: "info",
      position: "tc",
      autoDismiss: "5",
      dismissible: "both",
    });
  };
  render() {
    const previewPost = this.state.previewPost;
    return (
      <div className="">
        <NotificationSystem ref={this.notificationSystem} />
        {/* <SearchBar /> */}
        <div className="flex main-add-post">
          <div className="flex vertical-flex w-100 main-shadow">
            <div className="flex blue-underline">
              <button
                className={this.getClassNameT(-1)}
                value={"patundshmeri"}
                onClick={this.propertyTypeClicked}
              >
                {i18n.t('real estate')}

              </button>
              <button
                className={this.getClassNameT(PostTypes.Ahengje)}
                value={"gastronomi"}
                onClick={this.propertyTypeClicked}
              >
                {i18n.t('gastronomi')}

              </button>
              <button
                className={this.getClassNameT(PostTypes.Vetura)}
                onClick={this.propertyTypeClicked}
                value={'vetura'}
              >
                {i18n.t('cars')}

              </button>

            </div>

            <div className="flex flex-space-around w-90 m-2-perc wrap post-type-button-wrapper ">
              {![PostTypes.Ahengje, PostTypes.Vetura].includes(this.state.postTypeId) ? (
                <>
                  <div className="margin-side-5-px flex align-items-center text-align-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"shtepi"}
                      className={this.getClass("Shtëpi")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />

                    <label className="ml-5-px m-0 label-padding">{i18n.t('home')}</label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"banese"}
                      className={this.getClass("Banesë")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />

                    <label className="ml-5-px label-padding">{i18n.t('apartment')}</label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"toke"}
                      className={this.getClass("Tokë")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('land')}</label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      value={"zyre"}
                      name="post-type"
                      className={this.getClass("Zyrë")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('office')}</label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      value={"lokal"}
                      name="post-type"
                      className={this.getClass("Lokal")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />
                    <label className="ml-5-px label-padding">{i18n.t('local')}</label>
                  </div>
                  <div className="margin-side-5-px flex align-items-center">
                    <input
                      type="radio"
                      name="post-type"
                      value={"ville"}
                      className={this.getClass("Villë")}
                      onClick={(e) => this.propertyTypeClicked(e)}
                    />
                    <label className="ml-5-px m-0 text-center text-align-center label-padding">
                    {i18n.t('villa')}
                    </label>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {this.state.loading ? (
              <div className="img-section height-50-vh flex flex align-center flex-center z-index-front">
                <ClipLoader />
              </div>
            ) : (
              <div className="w-90 m-2-perc">
                {this.state.InputGroup != undefined ||
                  (this.state.InputGroup != null &&
                    this.state.InputGroup.length > 1) ? (
                  <CustomForm
                    loadingPhoto={this.loadingPhoto}
                    post={this.state.previewPost}
                    update={this.state.updateData}
                    getInput={this.updateFields}
                    previewData={this.previewData}
                    photoLoaded={this.photoLoad}
                    handleSubmit={this.updatePost}
                    facebookLinkVisibilityChanged={
                      this.facebookLinkVisibilityChanged
                    }
                    initialSetFalse={this.initialSetFalse}
                    initial={this.state.initial}
                    formclass="align-center flex  w-100 wrap"
                    data={this.state.InputGroup}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
            {previewPost &&
              previewPost != {} &&
              previewPost.photos &&
              previewPost.photos.length > 0 &&
              previewPost.photos[0].blobString != "" ? (
              <h3 className="small-image flex m-vertical-10-px">
                {i18n.t('main photo')}
              </h3>
            ) : (
              ""
            )}
            <div className="small-image flex m-vertical-10-px wrap flex-center align-center">
              {previewPost &&
                previewPost != {} &&
                previewPost.photos &&
                previewPost.photos.blobString ?
                previewPost.photos.map((image, index) => {
                  return (
                    <span
                      key={index}
                      onClick={(e) => this.changeImage(e, index, image)}
                    >
                      <SmallImage
                        deleteImage={this.deleteImage}
                        imageKey={index}
                        Image={image}
                        Alternative={"this is image " + index}
                      />
                    </span>
                  );
                })
                : ""}
              {this.state.loadingPhoto && (
                <ClipLoader color="blue" className="loader " />
              )}
            </div>
            <div className="m-2-perc relative ">
              <label htmlFor="" className="height-5-vh">
              {i18n.t('select location')}
              </label>
              <div className="map-section bg-white m-2-perc height-70-vh w-100 p-3 ">
                <GoogleMapCustom
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&region=us&language=en&libraries=places,geometry,drawing&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  loadingElement={<div style={{ height: "95%" }} />}
                  containerElement={<div style={{ height: "100%" }} />}
                  mapElement={<div style={{ height: "95%", width: "95%" }} />}
                  coordinatesSet={this.coordinatesSet}
                  clickMapForMarker={true}
                  postHasLocation={previewPost}
                />
              </div>
              <div className="flex align-center">
                <FormLabel className="red m-0">
                {i18n.t('do not show map')}
                </FormLabel>
                <FormControl
                  checked={!this.state.showMap}
                  className="show-map-checkbox"
                  onChange={this.hideMapChanged}
                  style={{ marginTop: "0px !important" }}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          <div className="inherit-all flex vertical-flex align-center separate-line sticky-pos">
            <h3>{i18n.t('preview')}</h3>
            <CardPost
              postStyle={this.state.previewPostStyle}
              post={previewPost}
              showPrice={true}
            />
          </div>
        </div>
        <div className="flex flex-center align-center add-post-div m-vertical-15-px">
          <Button
            disabled={this.state.processing}
            waitingResponse={this.state.processing}
            onClick={this.updatePost}
            className="bg-blue"
          >
            {i18n.t('update')}
          </Button>
          {this.state.processing && (
            <ClipLoader color="blue" className="loader" />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(UpdatePost);
